import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";

import { Calendar } from "../models/calendar";

@Injectable()
export class CalendarService extends BaseService {

  public async getAll() {
    return await this.getMany(Calendar, `api/calendar`, 0, 0);
  }

}
