import { Injectable } from '@angular/core';
import { SignedUri } from '../signed-uri';
import { BaseService } from "./base.service";

@Injectable()
export class AttachmentService extends BaseService {

  async upload(id: number, file: File) {
    const url = `api/attachment/inspection/${id}/attachment?name=${encodeURIComponent(file.name)}&type=${encodeURIComponent(file.type)}`;

    await this.http
      .post(url, file, { headers: await this.getHeaders() })
      .toPromise();
  }

  async uploadEthicalAssessment(id: number, file: File) {
    const url = `api/attachment/inspection/${id}/ethicalAssessment?name=${encodeURIComponent(file.name)}&type=${encodeURIComponent(file.type)}`;

    await this.http
      .post(url, file, { headers: await this.getHeaders() })
      .toPromise();
  }

  async getEthicalAssessmentTemplateUri() {
    const response = await this.http.get<{ documentUri: SignedUri }>('api/attachment/docs/ethicalAssessmentTemplate', { headers: await this.getHeaders() }).toPromise();
    return response.documentUri;
  }
}
