export class Calendar {
    inspectionID: number;
    inspectionType: number;
    inspectionInspectionDate: string;
    inspectionReportDate: string;
    inspectionState: number;
    inspectionStateComment: string;
    siteID: number;
    siteName: string;
    siteAddress: string;
    siteCountry: string;
    siteSiteNumber: number;
    inspectorID: number;
    inspectorExternalID: string;
    inspectorDisplayName: string;
}
