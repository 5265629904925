import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

import { SitesService } from "../data/sites.service";

@Component({
  selector: 'app-site-history',
  templateUrl: './site-history.component.html',
  styleUrls: ['./site-history.component.scss']
})
export class SiteHistoryComponent implements OnInit {

  history: Array<any>;
  siteID: number;

  constructor(
    private route: ActivatedRoute,
    private siteService: SitesService) { }

  async ngOnInit() {
    this.siteID = +this.route.snapshot.params.id;

    this.history = await this.siteService.getHistory(this.siteID);
  }

}
