export enum QualityArea {
    Leadership = 1,
    Training,
    DesignConstruction,
    SpecificationsStandards,
    WrittenProcedures,
    Validation,
    HousekeepingPestControl,
    StartingMaterials,
    Making,
    Packing,
    Records,
    QualityControl,
    FinishedProductRelease,
    StorageHandlingFinishedGoods,
    Complaints,
    AccountabilityContractors,
}
