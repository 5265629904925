import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactRole'
})
export class ContactRolePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value === "employeesCoordinator") return "Coordinator";
    if (value === "employeesFHCSContactPerson") return "Primary Contact";
    if (value === "employeesFHCSKeySupplierManager") return "FHCS Procurement Account Manager (PAM)";
    if (value === "employeesGMAdmin") return "GM Admin";
    if (value === "employeesOperation") return "Operation";
    if (value === "employeesQualityAssurance") return "Quality Assurance";
    if (value === "employeesResearchDevelopment") return "Research & Development";
    if (value === "employeesSiteQualityOfficer") return "Site Quality Officer";
    if (value === "lastPrimaryContact") return "Last Primary Contact";

    return null;
  }

}
