import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'siteNumber'
})
export class SiteNumberPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    if (!value) return null;

    const str = "0000" + value;
    return str.substr(str.length - 4);
  }

}
