import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";

import { Challenge } from "../models/challenge";

@Injectable()
export class ChallengesService extends BaseService {

  public async getAll() {
    return await this.getMany(Challenge, `api/challenge`, 0, 0);
  }

}
