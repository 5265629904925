import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';

import { Audit } from "../models/inspection";
import { AuditService } from "../data/audit.service";
import { InspectionsService } from "../data/inspections.service";
import { SitesService } from "../data/sites.service";

import { Observation } from "../models/observation";
import { Site } from "../models/site";

import { QualityArea } from '../shared/quality-area.enum';
import { InspectionType } from "../shared/inspection-type.enum";

@Component({
  selector: 'app-audit-edit',
  templateUrl: './audit-edit.component.html',
  styleUrls: ['./audit-edit.component.scss']
})
export class AuditEditComponent implements OnInit, OnDestroy {

  static readonly DefaultAuditPurpose: string = "Quality Assurance Audits provide an independent assessment of current conditions in facilities which manufacture, handle, or store products. Audits are based on application of the FHCS 16 Quality Key Areas and compliance with pertinent laws and regulations. The desired end-result is to achieve prompt and effective correction of observations listed on the report.";
  static readonly DefaultAuditSummary: string = "Make an initial summary describing whether or not the site has worked toward implementing and effectively utilizing the 16 QA Area System as a means to run the business over the last 12 months, or not.\n\nClearly state the overall QAC Rating XX%. Refer to the previous QAC rating and compare the improvement or lack of improvement.\n\nTell the story about the site include a short historical view of the site (how long in business, type of business, size of facility, basic processes, etc.)\n\nDescribe the highlights of the audit. What was good about the quality system? What was found to be in place, fully implemented, and working fully effectively?\n\nDescribe the opportunities for improvement. What was found to be missing, not implemented, or not working effectively in the quality system? Give a brief, clear direction of what changes need to be made in order to build the quality system to an acceptable standard or to the next level.";
  static readonly DefaultAuditFocus: string = "List the three key focus area identified in the audit result. Example:\n\nQA #4. Specifications / Standards: Continue work on specification development; Establish formal and consistent specification review and revision program utilizing engineering change notifications\n\nQA #9. Making: The making equipment, processes, and quality controls must be improved through investments in molds, improvements in processes, and trainings of all personnel\n\nQA#16.  Accountability for Contractors:  Continue to execute the supplier development program to effectively improve the key 29 vendors to at least 65% QAC rating or eliminate these suppliers from the supply chain.";
  static readonly DefaultAuditCritical: string = "Identify the observations that pose a significant risk to our Company, our Brands, our Products, or our End-Users";
  InspectionType = InspectionType;
  auditid: number;
  auditType: number;
  audit: Audit;

  siteid: number;
  site: Site;

  observationGroupSummeries = new Array<Observation>();
  observationGroups = new Array<Array<Observation>>();

  autoSaveSubscription: Subscription;
  lastAutoSave: Date;

  QualityArea = QualityArea;

  constructor(
    private inspectionsService: InspectionsService,
    private auditService: AuditService,
    private siteService: SitesService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  async ngOnInit() {
    this.auditid = +this.route.snapshot.params.auditid;
    this.siteid = +this.route.snapshot.params.siteid;
    this.auditType = +this.route.snapshot.queryParams['type'] || 1
    if (this.siteid) {
      this.audit = new Audit();
      this.audit.type = this.route.snapshot.queryParams['type'] || 1;
      this.audit.siteID = this.siteid;
      this.audit.observations = [];

      // add summary observations
      for (let i = QualityArea.Leadership; i <= QualityArea.AccountabilityContractors; i++)
        this.audit.observations.push(new Observation(1, +i, 0));

      this.site = await this.siteService.getSite(this.siteid);
    } else if (this.auditid) {
      //alert(this.route.snapshot.queryParams['type'])
      if(this.route.snapshot.queryParams['type'] == InspectionType.DigitalAudit){//Digital Audit Type
        this.audit = await this.auditService.getAuditDigital(this.auditid);
      }else{//On-Site Audit Type
        this.audit = await this.auditService.getAudit(this.auditid);
      }
      this.site = await this.siteService.getSite(this.audit.siteID);
    }

    if (this.audit && this.audit.purpose == null) {
      this.audit.purpose = AuditEditComponent.DefaultAuditPurpose;
    }

    if (this.audit && this.audit.summary == null) {
      this.audit.summary = AuditEditComponent.DefaultAuditSummary;
    }
    if (this.audit && this.audit.focus == null) {
      this.audit.focus = AuditEditComponent.DefaultAuditFocus;
    }
    if (this.audit && this.audit.critical == null) {
      this.audit.critical = AuditEditComponent.DefaultAuditCritical;
    }
    this.observationGroupSummeries = this.getObservationGroupSummaries();
    this.observationGroups = this.getObservations();

    this.autoSaveSubscription = interval(120 * 1000).subscribe(() => this.save(true));
  }

  ngOnDestroy() {
    this.autoSaveSubscription.unsubscribe();
    this.autoSaveSubscription = null;
  }

  addObservation(area: QualityArea) {
    const ob = new Observation(1, area, this.observationGroups[area - 1].length + 1);
    this.observationGroups[area - 1].push(ob);
    this.audit.observations.push(ob);
  }

  private getObservationGroupSummaries() {
    console.log("getObservationGroupSummaries");

    const groups = new Array<Observation>();

    for (let i = QualityArea.Leadership; i <= QualityArea.AccountabilityContractors; i++) {
      groups.push(null);
    }

    for (const ob of this.audit.observations.filter(x => x.number === 0)) {
      groups[ob.area - 1] = ob;
    }

    for (let i = 0; i < groups.length; i++) {
      if (groups[i] === null) {
        const ob = new Observation(1, i + 1, 0);
        groups[i] = ob;
        this.audit.observations.push(ob);
      }
    }

    return groups;
  }

  private getObservations() {
    console.log("getObservations");

    const groups = new Array<Array<Observation>>();

    for (let i = QualityArea.Leadership; i <= QualityArea.AccountabilityContractors; i++) {
      groups.push([]);
    }

    for (const ob of this.audit.observations.filter(x => x.number > 0)) {
      groups[ob.area - 1].push(ob);
    }

    return groups;
  }

  async save(autoSave: boolean) {
    const inspection = this.audit;

    await this.inspectionsService.save({
      id: inspection.id,
      focus: inspection.focus,
      critical: inspection.critical,
      purpose: inspection.purpose,
      summary: inspection.summary,
      observations: inspection.observations.map(ob => ({
        id: ob.id,
        area: ob.area,
        number: ob.number,
        action: ob.action,
        comment: ob.comment,
        score: ob.score,
        challenge: ob.challenge ? { id: ob.challenge.id } : null
      }))
    });

    if (autoSave) {
      this.lastAutoSave = new Date();
    }
  }

  async saveAndRedirect() {
    await this.save(false);

    if (this.auditid)
      this.router.navigate(['/audit', this.auditid], { relativeTo: this.route,queryParams:{type:this.auditType} });
    if (this.siteid)
      this.router.navigate(['/site', this.siteid], { relativeTo: this.route });
  }

  observationMove(data: MyDragData, offset: number) {
    const oldIndex = data.index;
    const newIndex = oldIndex + offset;

    if (newIndex < 0 || newIndex > data.list.length - 1)
      return false;

    this.move(data.list, oldIndex, newIndex);

    // re-number
    data.list.forEach((el, i) => {
      el.number = i + 1;
    });

    return false;
  }

  observationDelete(data: MyDragData) {
    const oldIndex = data.index;

    const removed = data.list.splice(oldIndex, 1)[0];
    const removedIndex = this.audit.observations.indexOf(removed);
    this.audit.observations.splice(removedIndex, 1);

    // re-number
    data.list.forEach((el, i) => {
      el.number = i + 1;
    });

    return false;
  }

  move(array, old_index, new_index) {
    if (new_index >= array.length) {
      let k = new_index - array.length;
      while ((k--) + 1) {
        array.push(undefined);
      }
    }
    array.splice(new_index, 0, array.splice(old_index, 1)[0]);
    return array;
  }

}

interface MyDragData {
  list: Observation[];
  item: Observation;
  index: number;
}
