import { Component, OnInit, ContentChild, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import * as d3 from 'd3';

import { ChartsService } from '../data/charts.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading = true;
  filters: any = {};

  constructor(private chartsService: ChartsService) {
  }

  async ngOnInit() {

    const task0 = this.chartsService.getSpeedo(0);
    const task1 = this.chartsService.getSpeedo(1);

    const data0 = (await task0).data;
    const data1 = (await task1).data;

    this.render("#chart-1", data0, 1, 2);
    this.render("#chart-2", data1, 1, 2);

    this.loading = false;
  }

  render(target: string, dataset: { [group: number]: ChartData[] }, group1: number, group2: number) {

    if (!dataset[1] || dataset[1].length === 0) return;
    if (!dataset[2] || dataset[2].length === 0) return;

    const width = 500;
    const height = 200;
    const paddingBottom = 20;
    const radius1 = Math.min(width, height - paddingBottom) * 0.86;
    const radius2 = Math.min(width, height - paddingBottom) * 0.59;
    const donutWidth = 48;
    const fontSize = 14;

    const color1 = d3.scaleOrdinal()
      .domain(["< 65 %", "< 75 %", "< 85 %", ">= 85 %"])
      .range(["#fc0d1b", "#fffd38", "#94ce58", "#19ae54"]);

    const color2 = d3.scaleOrdinal()
      .domain(["< 65 %", "< 75 %", "< 85 %", ">= 85 %"])
      .range(["#fc0d1b", "#fffd38", "#94ce58", "#19ae54"].map(x => d3.hsl(x).brighter(1 / 3)));

    const svg = d3.select(target)
      .append("svg")
      .attr('width', width)
      .attr('height', height);

    const renderArc = (radius: number, color, group: number) => {
      const arc = d3.arc()
        .innerRadius(radius - donutWidth)
        .outerRadius(radius);

      const pie = d3.pie()
        .startAngle(Math.PI / -2)
        .endAngle(Math.PI / 2)
        .value(d => d.count)
        .sort(null);

      const g1 = svg.append("g")
        .attr("transform", `translate(${(width / 2) - 72},${height - paddingBottom})`);

      const g2 = g1
        .selectAll("path")
        .data(pie(dataset[group]))
        .enter()
        .append("g");

      const filterTable = (d: { data: ChartData, value: number }) => {
        const newFilters = Object.assign({}, this.filters);
        newFilters.sites = JSON.stringify(d.data.sites);
        this.filters = Object.assign({}, newFilters);
      }

      const path = g2
        .append("path")
        .attr("stroke", "white")
        .attr("stroke-width", 1 / 3)
        .attr("d", arc)
        .attr("fill", (d: { data: ChartData, value: number }) => color(d.data.bucket))
        .on("click", filterTable);

      g2.append("svg:text")
        .attr("transform", d => { const [x, y]: Array<number> = arc.centroid(d); return `translate(${x},${y + fontSize / 2})`; })
        .attr("font-size", fontSize + "px")
        .attr("text-anchor", "middle")
        .attr("fill", "black")
        .text((d: { data: ChartData, value: number }) => d.value)
        .on("click", filterTable);

      let text = "";

      if (group === 1) text = new Date().getUTCFullYear().toString();
      if (group === 2) text = "Prev";

      g1.append("svg:text")
        .attr("transform", `translate(${(-radius + (donutWidth / 2))},16)`)
        .attr("font-size", (fontSize - 2) + "px")
        .attr("text-anchor", "middle")
        .attr("fill", "#333333")
        .text(text);
    }

    renderArc(radius2, color2, group2);
    renderArc(radius1, color1, group1);

    function renderAvg(radius: number, group: number, inside: boolean) {
      const avg = dataset[group][0].avg;
      const angle = Math.PI * (avg / 100);

      const gavg = svg.append("g")
        .attr("transform", `translate(${(width / 2) - 72},${height - paddingBottom})`);

      const foo = inside ? donutWidth : 0;
      const foo2 = inside ? donutWidth + 24 : -17;

      gavg.append("circle")
        .attr("r", 10)
        .attr("cx", -1 * (radius - foo) * Math.cos(angle))
        .attr("cy", -1 * (radius - foo) * Math.sin(angle))
        .attr("font-size", (fontSize - 3) + "px")
        .attr("stroke", "white")
        .attr("stroke-width", 1 / 3)
        .attr("fill", "white")
        .text((new DecimalPipe("en-us")).transform(avg, "1.0-0") + " %");

      gavg.append("text")
        .attr("transform", `translate(${-1 * (radius - foo) * Math.cos(angle)},${-1 * (radius - foo) * Math.sin(angle) + 5})`)
        .attr("font-size", fontSize + "px")
        .attr("text-anchor", "middle")
        .attr("fill", "#333333")
        .text("Ø");

      gavg.append("svg:text")
        .attr("transform", `translate(${-1 * (radius - foo2) * Math.cos(angle)},${-1 * (radius - foo2) * Math.sin(angle)})`)
        .attr("font-size", (fontSize - 3) + "px")
        .attr("text-anchor", "middle")
        .attr("fill", "#333333")
        .text((new DecimalPipe("en-us")).transform(avg, "1.0-0") + " %");
    }

    renderAvg(radius2, group2, true);
    renderAvg(radius1, group1, false);

    const g = svg.append("g")
      .attr("transform", `translate(${width - 128},${height / 2})`);

    g.append("svg:text")
      .attr("font-size", fontSize + "px")
      .attr("text-anchor", "middle")
      .attr("fill", "#333333")
      .text("% QAC");

    const lines = {
      "< 65": "< 65",
      "< 75": "< 75",
      "< 85": "< 85",
      ">= 85": "≥ 85",
    }

    let i = 0;
    for (const k in lines) {
      if (lines.hasOwnProperty(k)) {
        i++;
        const v = lines[k];

        const gl = g.append("g")
          .attr("transform", `translate(0,${18 * i})`);

        gl.append("line")
          .attr("x1", 36)
          .attr("y1", -5)
          .attr("x2", 36 + 14)
          .attr("y2", -5)
          .attr("stroke", color1(k))
          .attr("stroke-width", 14);

        gl.append("svg:text")
          .attr("font-size", fontSize + "px")
          .text(v);

      }
    }
  }
}

interface ChartData {
  group: number;
  bucket: string;
  count: number;
  avg: number;
  sites: Array<number>;
}
