import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { StrategicKeySuppliersListResult } from '../models/strategic-key-suppliers-list-result';

@Injectable()
export class StrategicKeySuppliersListResolver {

  constructor(private http: HttpClient, private router: Router) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<StrategicKeySuppliersListResult> {
    const uploadID = route.paramMap.get("uploadID");
    console.log("Resolving import results of " + uploadID);

    const result = await this.http.post<StrategicKeySuppliersListResult>(`api/import/strategic-key-suppliers-list/${uploadID}`, { commit: false }).toPromise();

    if (!result) {
      this.router.navigate(["/home"]);
      return null;
    }

    return result;
  }

}
