import { Component, OnInit, Input, Output, ViewChild, ContentChildren, EventEmitter, QueryList, Inject } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DatatableComponent, DataTableColumnDirective } from '@swimlane/ngx-datatable';

import { ApiResponse } from "../data/api-response";

import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('highlight', [
      transition('void => active', [
        animate(500, keyframes([
          style({ backgroundColor: '#FFF000', offset: 0.1 }),
          style({ backgroundColor: '*', offset: 1.0 }),
        ]))
      ])
    ])
  ]
})
export class TableComponent implements OnInit {

  @Input() siteHeight = 210;

  selected = new Array<any>();
  selectionType: string | null = null;

  busy = true;

  rows = new Array<any>();
  count = 0;
  offset = 0;
  limit = 20;

  get searchState() { return this.search && this.search.length > 0 ? "active" : "inactive"; }

  get start() { return this.storage.get(this.getCacheKey("start")); }
  set start(val: number) { this.storage.set(this.getCacheKey("start"), val); }

  get search() { return this.storage.get(this.getCacheKey("search")); }
  set search(val: string) { this.storage.set(this.getCacheKey("search"), val); }

  searchDebounce: Subject<string> = new Subject<string>();
  searchImmediate: Subject<string> = new Subject<string>();

  @Input() select: (event: any) => Promise<any>;
  @Input() fetchData: (start: number, limit: number) => ApiResponse<any>;
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();

  @Input() columns: any[];
  @Input() sorts: any[] = [];
  @Input() cache: string;
  @Input() customSearch: boolean;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @ContentChildren(DataTableColumnDirective)
  set columnTemplates(val: QueryList<DataTableColumnDirective>) {
    this.table.columnTemplates = val;
  }

  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {
    merge(
      this.searchImmediate,
      this.searchDebounce.pipe(debounceTime(800))).pipe(
      distinctUntilChanged())
      .subscribe(x => this.refresh());
  }

  ngOnInit() {
    const sort = this.storage.get(this.getCacheKey("sort"));

    if (sort) {
      this.sorts = sort;
    }

    this.refresh(true);
  }

  getCacheKey(key: string) {
    return `${window.location.pathname}|${this.cache}|${key}`;
  }

  async page(offset: number, limit: number) {
    this.start = offset * limit;
    this.offset = offset;
    this.limit = limit;

    this.busy = true;

    const response = await this.fetchData(this.start, limit);

    this.count = response.total;
    this.rows = response.data;
    this.busy = false;
  }

  refresh(init = false) {
    const minRowCount = 3;
    const maxRowCount = 50;

    const rowHeight = 42;
    const tableHeight = 110;

    const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const rowCount = Math.round((height - this.siteHeight - tableHeight) / rowHeight);

    this.limit = Math.max(Math.min(rowCount, maxRowCount), minRowCount);
    this.rows = [];

    if (init) {
      this.offset = Math.floor(this.start / this.limit);
      this.start = this.offset * this.limit;
    } else {
      this.offset = 0;
      this.start = 0;
    }

    // clear sub elements
    this.selected = [];
    if (this.onRefresh) this.onRefresh.emit();

    this.page(this.offset, this.limit);
  }

  filterTable(text: string) {
    this.search = text;
    this.refresh();
  }

  async onSelect(event: any) {
    if (this.select) await this.select(event);
  }

  onPage(event: { count: number, pageSize: number, limit: number, offset: number }) {
    this.page(event.offset, event.limit);
  }

  onSort(event: { sorts: { dir: string, prop: string }[], column: string, prevValue: any, newValue: any }) {
    this.sorts = event.sorts;
    this.refresh();
    this.storage.set(this.getCacheKey("sort"), this.sorts);
  }

  onSearchChange(text: string) {
    this.search = text;
    this.searchDebounce.next(this.search);
  }

  onSearchEnter() {
    this.searchImmediate.next(this.search);
  }
}
