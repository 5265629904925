import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";
import { ContactPerson } from "../models/contact-person";

@Injectable()
export class MailingService extends BaseService {

  async postSendAsync(inspectionId: number, recipient: ContactPerson, cc: Array<ContactPerson>, subject: string, body: string) {
    const data = {
      recipient: recipient,
      cc: cc,
      subject: subject,
      body: body,
    };

    return await this.http.post(`api/mailing/send/${inspectionId}`, data, { headers: await this.getHeaders() }).toPromise();
  }

  async postSendMultipleAsync(inspectionId: number, recipients: Array<ContactPerson>, cc: Array<ContactPerson>, subject: string, body: string) {
    const data = {
      recipients: recipients,
      cc: cc,
      subject: subject,
      body: body,
    };

    return await this.http.post(`api/mailing/send/${inspectionId}`, data, { headers: await this.getHeaders() }).toPromise();
  }

  isValidEmailAddress(value: string) {
    return value && value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/);
  }

  parseCc(cc: string) {

    const result = new Array<ContactPerson>();

    const parts = cc
      .replace(/^\s*;\s*/, "")
      .replace(/\s*;\s*$/, "")
      .split(/\s*;\s*/);

    for (const part of parts) {
      if (this.isValidEmailAddress(part)) {
        result.push({ EMail: part });
      }
    }

    return result;
  }
}
