import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Me } from '../../models/me';

@Injectable()
export class MeResolver {
  constructor(private http: HttpClient, private router: Router) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("Resolving me");

    const me = await this.http.get<Me>('api/info/me').toPromise();

    if (!me) {
      this.router.navigate(["/settings"]);
      return null;
    }

    return me;
  }

}
