import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommentPromptComponent } from "../comment-prompt/comment-prompt.component";
import { AssessmentService } from "../data/assessment.service";
import { AttachmentService } from "../data/attachment.service";
import { AuditService } from "../data/audit.service";
import { DocumentService } from "../data/document.service";
import { InspectionsService } from "../data/inspections.service";
import { Assessment, Audit, Inspection } from "../models/inspection";
import { EthicalAssessmentResult } from '../shared/ethical-assessment-result.enum';
import { InspectionState } from '../shared/inspection-state.enum';
import { InspectionType } from '../shared/inspection-type.enum';




@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements OnInit {

  InspectionState = InspectionState;
  EthicalAssessmentResult = EthicalAssessmentResult;

  canEdit = false;
  ethicalAssessmentSaved = false;

  inspectionID: number;
  auditType: number|string;
  inspection: Inspection;

  reportUrlForViewer: { url: string; httpHeaders: any; withCredentials: boolean };
  actionPlanUrl: string;

  constructor(
    private route: ActivatedRoute,
    private auditService: AuditService,
    private assessmentService: AssessmentService,
    private inspectionsService: InspectionsService,
    public documentService: DocumentService,
    private attachmentService: AttachmentService,
    private modalService: NgbModal) { }

  async ngOnInit() {
    this.inspectionID = +this.route.snapshot.params.id;
    if (this.inspectionID) {
      await this.loadInspection();
    }

    if (this.inspection) {

      this.reportUrlForViewer = {
        url: this.documentService.getReportUrl(this.inspection.id),
        httpHeaders: await this.documentService.getHeadersForViewer(),
        withCredentials: true,
      };

      this.actionPlanUrl = this.documentService.getActionPlanUrl(this.inspection.id);
    }

    this.canEdit = !this.inspection.site.lockedAt && this.inspection.state >= InspectionState.InProgress && this.inspection.state < InspectionState.Completed;
  }

  async loadInspection() {
    
    this.auditType = this.route.snapshot.queryParams['type'] || 1;
  
    if (this.route.routeConfig.path.startsWith("audit/")) {
      if(this.auditType == InspectionType.Audit){ //On-Site Audit type
        this.inspection = await this.auditService.getAudit(this.inspectionID);
      }else{ //DIgital Audit type
        this.inspection = await this.auditService.getAuditDigital(this.inspectionID);
      }
    } else if (this.route.routeConfig.path.startsWith("assessment/")) {
      this.inspection = await this.assessmentService.getAssessment(this.inspectionID);
    }
  }

  async onUploadFile(files?: FileList) {
    if (files && files.length === 1) {
      await this.attachmentService.upload(this.inspection.id, files[0]);
      await this.loadInspection();
    }
  }

  async onUploadEthicalAssessment(files?: FileList) {
    this.ethicalAssessmentSaved = false;
    if (files && files.length === 1) {
      await this.attachmentService.uploadEthicalAssessment(this.inspection.id, files[0]);
      await this.loadInspection();

      this.ethicalAssessmentSaved = true;
    }
  }

  async onCommentEthicalAssessment() {
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async (result: string) => {
        await this.inspectionsService.postEthicalAssessmentComment(this.inspectionID, result);
        await this.loadInspection();

        this.ethicalAssessmentSaved = true;
      },
      reason => { });

    ref.componentInstance.title = "Add comment";
    ref.componentInstance.description = "Comment";
    ref.componentInstance.required = true;
  }

  async addComment() {
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async (result: string) => {
        await this.inspectionsService.postComment(this.inspectionID, result);
        await this.loadInspection();
      },
      reason => { });

    ref.componentInstance.title = "Add comment";
    ref.componentInstance.description = "Comment";
    ref.componentInstance.required = true;
  }

  onEthicalAssessmentResultChange() {
    this.ethicalAssessmentSaved = false;
    if (this.inspection.type === InspectionType.Audit) {
      const audit = this.inspection as Audit;
      this.auditService.setEthicalAssessmentResult(audit.id, audit.ethicalAssessment)
      this.ethicalAssessmentSaved = true;
    } else if (this.inspection.type === InspectionType.Assessment) {
      const assessment = this.inspection as Assessment;
      this.assessmentService.setEthicalAssessmentResult(assessment.id, assessment.ethicalAssessment)
      this.ethicalAssessmentSaved = true;
    }
  }

}
