import { Pipe, PipeTransform } from '@angular/core';
import { EthicalAssessmentResult } from '../ethical-assessment-result.enum';

@Pipe({
  name: 'ethicalAssessmentResult'
})
export class EthicalAssessmentResultPipe implements PipeTransform {

  transform(value: number, args?: any): any {

    const full = args === 'full';

    if (!value)
      return full ? "Not Rated (NR)" : "NR";

    switch (+value) {
      case EthicalAssessmentResult.Unknown: return full ? "Not Rated (NR)" : "NR";
      case EthicalAssessmentResult.NotCarriedOut: return full ? "Not Rated (NR)" : "NR";
      case EthicalAssessmentResult.NotPassed: return full ? "Not Passed (NOK)" : "NOK";
      case EthicalAssessmentResult.Passed: return full ? "Passed (OK)" : "OK";
    }
  }

}
