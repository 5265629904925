import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Region } from '../../models/region';

@Injectable()
export class RegionsResolver {
  constructor(private http: HttpClient, private router: Router) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Region[]> {
    console.log("Resolving regions");

    const regions = await this.http.get<Region[]>(`api/regions`).toPromise();

    if (!regions) {
      this.router.navigate(["/home"]);
      return null;
    }

    return regions;
  }

}
