import { Pipe, PipeTransform } from '@angular/core';

import { InspectionState } from '../inspection-state.enum';

@Pipe({
  name: 'inspectionState'
})
export class InspectionStatePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    switch (+value) {
      case InspectionState.Unknown: return "Unknown";
      case InspectionState.Cancelled: return "Cancelled";
      case InspectionState.Proposed: return "Proposed";
      case InspectionState.Rejected: return "Rejected";
      case InspectionState.Planned: return "Planned";
      case InspectionState.PlannedSentOut: return "Planned (sent out)";
      case InspectionState.Confirmed: return "Confirmed";
      case InspectionState.InProgress: return "In Progress";
      case InspectionState.AwaitingActionPlan1: return "Awaiting Action Plan (7 days)";
      case InspectionState.AwaitingActionPlan2: return "Awaiting Action Plan (30 days)";
      case InspectionState.AwaitingCloseOutReport1: return "Awaiting Close Out Report (90 days)";
      case InspectionState.AwaitingCloseOutReport2: return "Awaiting Close Out Report (180 days)";
      case InspectionState.Completed: return "Completed";
    }
  }

}
