import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdalGuard } from 'adal-angular4';

import { RegionResolver } from './services/region-resolver.service';
import { RegionUsersResolver } from './services/region-users-resolver.service';
import { RegionsResolver } from './services/regions-resolver.service';
import { StrategicKeySuppliersListResolver } from './services/strategic-key-suppliers-list-resolver.service';
import { CountryInfosResolver } from '../shared/services/country-infos-resolver.service';
import { MeResolver } from './services/me-resolver.service';

import { IndexComponent } from './components/index/index.component';
import { RegionEditComponent } from './components/region-edit/region-edit.component';
import { StrategicKeySuppliersListReviewComponent } from './components/strategic-key-suppliers-list-review/strategic-key-suppliers-list-review.component';
import { NewSiteComponent } from './components/new-site/new-site.component';

const routes: Routes = [
  {
    path: 'settings',
    component: IndexComponent,
    canActivate: [AdalGuard],
    resolve: {
      regions: RegionsResolver,
    },
    children: [{
      path: 'regions/:regionID',
      component: RegionEditComponent,
      runGuardsAndResolvers: 'always',
      resolve: {
        region: RegionResolver,
        users: RegionUsersResolver,
        me: MeResolver,
      }
    }, {
      path: 'strategic-key-suppliers-list/review/:uploadID',
      component: StrategicKeySuppliersListReviewComponent,
      runGuardsAndResolvers: 'always',
      resolve: {
        result: StrategicKeySuppliersListResolver,
      }
    }, {
      path: 'site/new',
      component: NewSiteComponent,
      runGuardsAndResolvers: 'always',
      resolve: {
        countryInfos: CountryInfosResolver,
        regions: RegionsResolver,
      }
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [RegionResolver]
})
export class SettingsRoutingModule { }
