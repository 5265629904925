import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { AssessmentService } from "../data/assessment.service";
import { ChallengesService } from "../data/challenges.service";
import { InspectionsService } from "../data/inspections.service";
import { SitesService } from "../data/sites.service";
import { Challenge } from "../models/challenge";
import { Assessment } from "../models/inspection";
import { Observation } from "../models/observation";
import { Site } from "../models/site";
import { QualityArea } from '../shared/quality-area.enum';

@Component({
  selector: 'app-assessment-edit',
  templateUrl: './assessment-edit.component.html',
  styleUrls: ['./assessment-edit.component.scss']
})
export class AssessmentEditComponent implements OnInit, OnDestroy {

  static readonly DefaultAssessmentPurpose: string = "Quality Assurance Assessments provide an independent assessment of current conditions in facilities which manufacture, handle, or store products. Assessments are based on application of the FHCS 16 Quality Key Areas and compliance with pertinent laws and regulations. The desired end-result is to achieve prompt and effective correction of observations listed on the report.";
  static readonly DefaultAssessmentSummary: string = "Make an initial summary describing whether or not the site has worked toward implementing and effectively utilizing the 16 QA Area System as a means to run the business over the last 12 months, or not.\n\nClearly state the overall QAC Rating XX%. Refer to the previous QAC rating and compare the improvement or lack of improvement.\n\nTell the story about the site include a short historical view of the site (how long in business, type of business, size of facility, basic processes, etc.)\n\nDescribe the highlights of the audit. What was good about the quality system? What was found to be in place, fully implemented, and working fully effectively?\n\nDescribe the opportunities for improvement. What was found to be missing, not implemented, or not working effectively in the quality system? Give a brief, clear direction of what changes need to be made in order to build the quality system to an acceptable standard or to the next level.";
  static readonly DefaultAssessmentFocus: string = "List the three key focus area identified in the audit result. Example:\n\nQA #4. Specifications / Standards: Continue work on specification development; Establish formal and consistent specification review and revision program utilizing engineering change notifications\n\nQA #9. Making: The making equipment, processes, and quality controls must be improved through investments in molds, improvements in processes, and trainings of all personnel\n\nQA#16.  Accountability for Contractors:  Continue to execute the supplier development program to effectively improve the key 29 vendors to at least 65% QAC rating or eliminate these suppliers from the supply chain.";
  static readonly DefaultAssessmentCritical: string = "Identify the observations that pose a significant risk to our Company, our Brands, our Products, or our End-Users";

  assessmentid: number;
  assessment: Assessment;

  siteid: number;
  site: Site;

  challenges = new Array<Challenge>();
  observations = new Array<Array<Observation>>();

  autoSaveSubscription: Subscription;
  lastAutoSave: Date;

  QualityArea = QualityArea;

  get allQualityAreas() {
    return Object.keys(QualityArea).filter(x => x.length < 3);
  }

  constructor(
    private inspectionsService: InspectionsService,
    private assessmentService: AssessmentService,
    private siteService: SitesService,
    private challengesService: ChallengesService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  async ngOnInit() {
    this.assessmentid = +this.route.snapshot.params.assessmentid;
    this.siteid = +this.route.snapshot.params.siteid;

    if (this.siteid) {
      this.assessment = new Assessment();
      this.assessment.type = 2;
      this.assessment.siteID = this.siteid;
      this.assessment.observations = [];

      this.site = await this.siteService.getSite(this.siteid);
    } else if (this.assessmentid) {
      this.assessment = await this.assessmentService.getAssessment(this.assessmentid);
      this.site = await this.siteService.getSite(this.assessment.siteID);
    }

    if (this.assessment && this.assessment.purpose == null) {
      this.assessment.purpose = AssessmentEditComponent.DefaultAssessmentPurpose;
    }

    if (this.assessment && this.assessment.summary == null) {
      this.assessment.summary = AssessmentEditComponent.DefaultAssessmentSummary;
    }

    if (this.assessment && this.assessment.focus == null) {
      this.assessment.focus = AssessmentEditComponent.DefaultAssessmentFocus;
    }

    if (this.assessment && this.assessment.critical == null) {
      this.assessment.critical = AssessmentEditComponent.DefaultAssessmentCritical;
    }

    this.challenges = (await this.challengesService.getAll()).data;
    this.observations = this.getObservations();

    this.autoSaveSubscription = interval(120 * 1000).subscribe(() => this.save(true));
  }

  ngOnDestroy() {
    this.autoSaveSubscription.unsubscribe();
    this.autoSaveSubscription = null;
  }

  getObservations() {
    const groups = new Array<Array<Observation>>();

    for (let i = QualityArea.Leadership; i <= QualityArea.AccountabilityContractors; i++) {
      groups.push([]);
    }

    for (const ob of this.assessment.observations.filter(x => x.number > 0)) {
      groups[ob.area - 1].push(ob);
    }

    for (const challenge of this.challenges) {
      const grp = groups[challenge.area - 1];

      if (grp.filter(x => x.challenge.id === challenge.id).length === 0) {
        // override number for all new Observations use Challenge.number
        const ob = new Observation(2, challenge.area, challenge.number);
        ob.action = "";
        ob.challengeID = challenge.id;
        ob.challenge = challenge;
        grp.push(ob);

        this.assessment.observations.push(ob);
      }
    }

    // override number for all Observations use Challenge.number and sort
    for (let i = 0; i < groups.length; i++) {
      groups[i].forEach(x => x.number = x.challenge.number);
      groups[i] = groups[i].sort((a, b) => a.number - b.number);
    }

    return groups;
  }

  addObservation(area: QualityArea) {
    const count = this.getObservationsFor(area).length;
    this.assessment.observations.push(new Observation(1, +area, count + 1));
  }

  getObservationsFor(area: QualityArea) {
    if (!this.assessment || !this.assessment.observations) return [];
    return this.assessment.observations.filter(x => x.area === area && x.number > 0);
  }

  countScore(group: Observation[], value: number | null) {
    return group.filter(x => x.score === value).length;
  }

  async save(autoSave: boolean) {
    const inspection = this.assessment;

    // remove dummy observations that have not been scored
    const observations = this.assessment.observations.filter(x => x.score !== undefined);

    await this.inspectionsService.save({
      id: inspection.id,
      focus: inspection.focus,
      critical: inspection.critical,
      purpose: inspection.purpose,
      summary: inspection.summary,
      observations: observations.map(ob => ({
        id: ob.id,
        area: ob.area,
        number: ob.number,
        action: ob.action,
        comment: ob.comment,
        score: ob.score,
        challenge: ob.challenge ? { id: ob.challenge.id } : null
      }))
    });

    if (autoSave) {
      this.lastAutoSave = new Date();
    }
  }

  async saveAndRedirect() {
    await this.save(false);

    if (this.assessmentid)
      this.router.navigate(['/assessment', this.assessmentid], { relativeTo: this.route });
    if (this.siteid)
      this.router.navigate(['/site', this.siteid], { relativeTo: this.route });
  }

}
