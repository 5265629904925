import { Challenge } from './challenge';
import { QualityArea } from '../shared/quality-area.enum';

export class Observation {
    id: number;

    action: string | null;
    comment: string | null;

    score: number | null;

    challengeID: number | null;
    challenge: Challenge | null;

    inspectionID: number;

    constructor(public type: number, public area: QualityArea, public number: number | null) {
    }
}
