import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Site } from "../models/site";
import { Region } from '../models/region';
import { CountryInfo } from '../models/country-info';

@Component({
  selector: 'app-site-detail-edit',
  templateUrl: './site-detail-edit.component.html',
  styleUrls: ['./site-detail-edit.component.scss']
})
export class SiteDetailEditComponent {

  @Input() site: Site;

  regions$ = this.http.get<Region[]>("api/regions");
  countryInfos$ = this.http.get<CountryInfo[]>("api/countryinfos");

  constructor(public activeModal: NgbActiveModal, private http: HttpClient) { }

  save(site: Site) {
    this.activeModal.close(site);
  }
}
