import { Component, OnInit, Input } from '@angular/core';

import { InspectionState } from '../../inspection-state.enum';

@Component({
  selector: 'app-rating-badge',
  templateUrl: './rating-badge.component.html',
  styleUrls: ['./rating-badge.component.scss']
})
export class RatingBadgeComponent implements OnInit {
  InspectionState = InspectionState;

  @Input() type: number;
  @Input() state: InspectionState;
  @Input() result: number | undefined;

  constructor() { }

  ngOnInit() {
  }

  typeof(val) {
    return typeof val;
  }

}
