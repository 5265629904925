import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-strategic-key-suppliers-list-import',
  templateUrl: './strategic-key-suppliers-list-import.component.html',
  styleUrls: ['./strategic-key-suppliers-list-import.component.scss']
})
export class StrategicKeySuppliersListImportComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
  }

  async onUploadFile(files: FileList | null) {

    if (files && files.length === 1) {
      const file = files[0];
      const url = `api/import/strategic-key-suppliers-list?type=${encodeURIComponent(file.type)}`;

      const toast = this.toastr.info("Uploading file...", "Please wait", {
        disableTimeOut: true
      });

      const uploadId = await this.http.post<string>(url, file).toPromise();

      toast.toastRef.close();

      this.toastr.success("File uploaded! Processing...");
      this.router.navigate(["strategic-key-suppliers-list", "review", uploadId], { relativeTo: this.route });
    }

  }
}