import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TableComponent } from '../table/table.component';
import { ApiResponse } from "../data/api-response";

import { InspectionsService } from "../data/inspections.service";
import { DocumentService } from '../data/document.service';

import { InspectionType } from "../shared/inspection-type.enum";

@Component({
  selector: 'app-inspection-list',
  templateUrl: './inspection-list.component.html',
  styleUrls: ['./inspection-list.component.scss']
})
export class InspectionListComponent implements OnInit {

  InspectionType = InspectionType;

  filters: any = {};

  @ViewChild(TableComponent) table: TableComponent;

  @Input() type: InspectionType | null;
  @Input() siteID: number;

  fetchDataFn: (start: number, limit: number) => Promise<ApiResponse<any>>;

  constructor(private inspectionsService: InspectionsService, public documentService: DocumentService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    if (this.type) this.filters.type = this.type;
    if (this.siteID) this.filters.siteID = this.siteID;

    this.table.sorts = [{ prop: 'reportDate', dir: 'desc' }];

    this.fetchDataFn = async (start: number, limit: number) =>
      await this.inspectionsService.getInspectionList(start, limit, this.table.sorts[0], this.filters, this.table.search);
  }

  createAudit() {
    this.router.navigate(['audit/new'], { relativeTo: this.route });
  }

}
