import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";

import { Me } from "../models/me";

@Injectable()
export class InfoService extends BaseService {

  private me: Me = null;

  public async getMe() {
    return this.me || (this.me = await this.getSingle(Me, 'api/info/me'));
  }

}
