import { SignedUri } from "../signed-uri";
import { InspectionState } from '../shared/inspection-state.enum';
import { CountryInfo } from "./country-info";
import { RegionSite } from "./region-site";
import { Classification } from "../shared/classification.enum";
import { Categories } from "../shared/categories.enum";
import { EthicalAssessmentResult } from "../shared/ethical-assessment-result.enum";

export class Site {
    id: number;
    name: string;
    address: string;
    sapNumbers: string[];
    siteNumber: number;
    turnover: number;
    location: string;
    materialType: string;
    classification: Classification;
    categories: Categories;
    equipmentSoftware: string;

    siteType: string;
    siteCategory: string;
    risk: string;
    materialGroup: string;
    comment: string;
    references: string;
    employeesGMAdmin: string;
    employeesQualityAssurance: string;
    employeesOperation: string;
    employeesResearchDevelopment: string;
    employeesCoordinator: string;
    employeesSiteQualityOfficer: string;
    employeesFHCSKeySupplierManager: string;
    employeesFHCSContactPerson: string;
    productsProduced: string;
    isObsolete: boolean;
    lockedAt: string;

    logoUri: SignedUri;
    siteInfoUri: SignedUri;

    latestInspectionID?: number;
    latestInspectionType?: number;
    latestInspectionState?: InspectionState;
    latestInspectionEthicalAssessment?: EthicalAssessmentResult;
    latestInspectionResult?: number;
    latestInspectorDisplayName?: string;
    latestInspectorExternalID?: string;

    nextInspectionID?: number;
    nextInspectionType?: number;
    nextInspectionState?: InspectionState;
    nextInspectionEthicalAssessment?: EthicalAssessmentResult;
    nextInspectionResult?: number;
    nextInspectorDisplayName?: string;
    nextInspectorExternalID?: string;

    countryInfo: CountryInfo;
    regions: RegionSite[];
}
