import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-inspection-planner',
  templateUrl: './inspection-planner.component.html',
  styleUrls: ['./inspection-planner.component.scss']
})
export class InspectionPlannerComponent implements OnInit {

  @Input() selectedDate: Date;
  comment: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  onDateSelected(date: Date) {
    this.activeModal.close({ date: date, comment: this.comment });
  }
}
