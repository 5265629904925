import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AdalService } from "adal-angular4";
import { environment } from '../../environments/environment';

import { ApiResponse } from './api-response';

@Injectable()
export class BaseService {

  constructor(private authService: AdalService, protected http: HttpClient) { }

  get AuthorizationHeader$() {
    return this.authService.acquireToken(environment.adalConfig.clientId)
      .pipe(map(x => `Bearer ${x}`));
  }

  async getHeaders(contentType = 'application/json') {
    return new HttpHeaders()
      .set('Content-Type', contentType)
      .set("Authorization", "Bearer "+localStorage.getItem('adal.idtoken'));
  }

  async getSignHeaders(contentType = 'application/json') {
    return new HttpHeaders()
      .set('Content-Type', contentType);
  }

  protected async getSingle<T>(type: { new(): T; }, url: string) {
    const response = await this.get(url);

    if (!response)
      return null;

    return Object.assign(new type(), response.data);
  }

  protected async getMany<T>(type: { new(): T; }, url: string, start, limit, sort?: { prop: string, dir: string }, filters?: any, search?: string) {
    const response = await this.get<Array<T>>(url, start, limit, sort, filters, search);
    return new ApiResponse(response.data.map(x => Object.assign(new type(), x)), response.total);
  }

  protected async get<T>(url: string, start?: number, limit?: number, sort?: { prop: string, dir: string }, filters?: any, search?: string) {

    let params = new HttpParams();

    for (const k in filters) {
      if (typeof filters[k] === 'number' || typeof filters[k] === 'string' || typeof filters[k] === 'boolean')
        params = params.set(k, filters[k]);
    }

    if (typeof start === 'number') {
      params = params.set("start", start.toString());
    }

    if (typeof limit === 'number') {
      params = params.set("limit", limit.toString());
    }

    if (sort) {
      params = params.set("sort", sort.prop);
      params = params.set("dir", sort.dir);
    }

    if (search) {
      params = params.set("search", search);
    }

    const response = await this.http
      .get<T>(url, {
        params: params,
        headers: await this.getHeaders(),
        observe: "response",
      })
      .toPromise();

    const count = parseInt(response.headers.get("X-Total-Rows"), 10);

    return new ApiResponse<T>(response.body, count);
  }
}
