import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader, LatLng } from '@agm/core';
import { ParsePipe, MomentModule } from 'ngx-moment';
import * as moment from "moment";

import { SitesService } from "../data/sites.service";
import { AuditService } from "../data/audit.service";
import { AssessmentService } from "../data/assessment.service";
import { MailingService } from "../data/mailing.service";
import { InfoService } from '../data/info.service';
import { DocumentService } from "../data/document.service";
import { AttachmentService } from "../data/attachment.service";
import { Site } from "../models/site";
import { Audit } from "../models/inspection";
import { Assessment } from "../models/inspection";
import { Observation } from "../models/observation";
import { ContactPerson } from "../models/contact-person";
import { Me } from "../models/me";

import { SiteDetailEditComponent } from "../site-detail-edit/site-detail-edit.component";
import { SiteProfileEditComponent } from "../site-profile-edit/site-profile-edit.component";
import { ContactEditComponent } from "../contact-edit/contact-edit.component";
import { ReassignInspectorComponent } from "../reassign-inspector/reassign-inspector.component";
import { CommentPromptComponent } from "../comment-prompt/comment-prompt.component";
import { SendMailInspectionComponent } from '../send-mail-inspection/send-mail-inspection.component';
import { InspectionPlannerComponent } from '../inspection-planner/inspection-planner.component';

import { InspectionState } from '../shared/inspection-state.enum';
import { InspectionType } from '../shared/inspection-type.enum';
import { EthicalAssessmentResult } from "../shared/ethical-assessment-result.enum";

declare var google: any;

@Component({
  selector: "app-site",
  templateUrl: "./site.component.html",
  styleUrls: ["./site.component.scss"]
})
export class SiteComponent implements OnInit {

  InspectionState = InspectionState;
  InspectionType = InspectionType;
  EthicalAssessmentResult = EthicalAssessmentResult;

  private _latestAudit: Audit;
  private _nextAudit: Audit;
  private _latestAssessment: Assessment;
  private _nextAssessment: Assessment;

  isAdmin = false;
  showHistory = false;
  showActions = false;

  me$: Promise<Me>;
  me: Me;

  siteID: number;
  site: Site;
  busy: boolean;

  public get latestAudit(): Audit {
    return this._latestAudit;
  }
  public set latestAudit(value: Audit) {
    this._latestAudit = value;
    this.latestAuditObservationsLessThan6 = this.filterAuditObservationsLessThan6(value);
  }

  public get nextAudit(): Audit {
    return this._nextAudit;
  }
  public set nextAudit(value: Audit) {
    this._nextAudit = value;
    this.nextAuditObservationsLessThan6 = this.filterAuditObservationsLessThan6(value);

    if (!this.latestAudit || !this.latestAudit.id) {
      this.nextAuditEthicalAssessmentFirst = true;
    } else if (this.latestAudit.ethicalAssessment !== EthicalAssessmentResult.Passed) {
      this.nextAuditEthicalAssessmentNotPassed = true;
    } else if (moment(this.latestAudit.inspectionDate).add(3, 'years') < moment()) {
      this.nextAuditEthicalAssessmentExpired = true;
    } else {
      this.nextAuditEthicalAssessmentValid = true;
    }
  }

  public get latestAssessment(): Assessment {
    return this._latestAssessment;
  }
  public set latestAssessment(value: Assessment) {
    this._latestAssessment = value;
  }

  public get nextAssessment(): Assessment {
    return this._nextAssessment;
  }
  public set nextAssessment(value: Assessment) {
    this._nextAssessment = value;

    if (!this.latestAssessment || !this.latestAssessment.id) {
      this.nextAssessmentEthicalAssessmentFirst = true;
    } else if (this.latestAssessment.ethicalAssessment !== EthicalAssessmentResult.Passed) {
      this.nextAssessmentEthicalAssessmentNotPassed = true;
    } else if (moment(this.latestAssessment.inspectionDate).add(3, 'years') < moment()) {
      this.nextAssessmentEthicalAssessmentExpired = true;
    } else {
      this.nextAssessmentEthicalAssessmentValid = true;
    }
  }

  latestAuditObservationsLessThan6: Observation[];
  nextAuditObservationsLessThan6: Observation[];

  nextAuditEthicalAssessmentValid: boolean;
  nextAuditEthicalAssessmentFirst: boolean;
  nextAuditEthicalAssessmentNotPassed: boolean;
  nextAuditEthicalAssessmentExpired: boolean;

  nextAssessmentEthicalAssessmentValid: boolean;
  nextAssessmentEthicalAssessmentFirst: boolean;
  nextAssessmentEthicalAssessmentNotPassed: boolean;
  nextAssessmentEthicalAssessmentExpired: boolean;

  mapLoading = true;
  lat = 0;
  lng = 0;

  expandedDetail = "";

  isNextAuditHistoryCollapsed = true;
  isNextAssessmentHistoryCollapsed = true;
  isLatestAuditHistoryCollapsed = true;
  isLatestAssessmentHistoryCollapsed = true;

  constructor(
    private route: ActivatedRoute,
    private siteService: SitesService,
    private auditService: AuditService,
    private assessmentService: AssessmentService,
    private mailingService: MailingService,
    private infoService: InfoService,
    public documentService: DocumentService,
    private attachmentService: AttachmentService,
    private modalService: NgbModal,
    private mapsApiLoader: MapsAPILoader) {
  }

  async ngOnInit() {
    const meTask = this.infoService.getMe();

    this.siteID = +this.route.snapshot.params.id;

    if (this.siteID) {
      this.site = await this.siteService.getSite(this.siteID);

      const mapTask = this.refreshMap();

      const latestAudit = this.auditService.getLatest(this.siteID, true);
      const nextAudit = this.auditService.getLatest(this.siteID, false);
      const latestAssessment = this.assessmentService.getLatest(this.siteID, true);
      const nextAssessment = this.assessmentService.getLatest(this.siteID, false);

      // apply site stuff
      if (!this.expandedDetail && this.site.productsProduced) this.expandedDetail = "site-productsProduced";
      if (!this.expandedDetail && this.site.materialType) this.expandedDetail = "site-materialType";
      if (!this.expandedDetail && this.site.siteCategory) this.expandedDetail = "site-siteCategory";
      if (!this.expandedDetail && this.site.equipmentSoftware) this.expandedDetail = "site-equipmentSoftware";
      if (!this.expandedDetail && this.site.comment) this.expandedDetail = "site-comment";
      if (!this.expandedDetail && this.site.risk) this.expandedDetail = "site-risk";
      if (!this.expandedDetail && this.site.location) this.expandedDetail = "site-location";

      await mapTask;

      this.latestAudit = await latestAudit;
      this.nextAudit = await nextAudit;
      this.latestAssessment = await latestAssessment;
      this.nextAssessment = await nextAssessment;

    } else {

      this.site = new Site();

    }

    this.me$ = meTask;
    this.me = await this.me$;
    this.isAdmin = this.me.permissions.admin;
    this.showHistory = this.me.permissions.admin || this.me.permissions.auditor || this.me.permissions.assessor;
    this.showActions = this.me.permissions.admin || this.me.permissions.auditor || this.me.permissions.assessor;
  }

  collapseHistory(history: Array<any>, flag: boolean) {
    if (!history || history.length === 0) return [];
    return flag ? [history[0]] : history;
  }

  async refreshMap() {

    if (!this.site.address) {
      this.mapLoading = false;
      return;
    }

    try {
     // const { lat, lng } = await this.getGeoLocation(this.site.address + "\r\n" + this.site.countryInfo.nameEnglish);

      // this.lat = lat();
      // this.lng = lng();
      this.mapLoading = false;
    } catch (e) {
      this.mapLoading = false;
    }
  }

  async getGeoLocation(address: string): Promise<LatLng> {

    await this.mapsApiLoader.load();

    const geocoder = new google.maps.Geocoder();

    console.log("Searching address", address);

    return new Promise<LatLng>((resolve, reject) => {
      geocoder.geocode({ 'address': address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve(results[0].geometry.location);
        } else {
          reject(status);
        }
      });
    });
  }

  async save(form: NgForm) {
    // await this.siteService.saveSoftwarePackage(this.model);
    // form.form.reset(this.model);
    // this.router.navigate(['/softwarepackages']);
  }

  async delete() {
    // await this.siteService.deleteSoftwarePackage(this.model.id);
    // this.router.navigate(['/softwarepackages']);
  }

  async lockSite() {
    this.busy = true;
    try {
      await this.siteService.lockSite(this.siteID);
      this.site = await this.siteService.getSite(this.siteID);
    }
    finally {
      this.busy = false;
    }
  }

  async unlockSite() {
    await this.siteService.unlockSite(this.siteID);
    this.site = await this.siteService.getSite(this.siteID);
  }

  filterAuditObservationsLessThan6(value: Audit): any {
    if (value && value.observations && value.observations.length > 0) {
      return value.observations
        .filter(ob => ob.number === 0 && ob.score !== null && ob.score >= 0 && ob.score < 6)
        .sort((a, b) => a.area - b.area);
    } else {
      return [];
    }
  }

  openDetailEdit() {
    const ref = this.modalService.open(SiteDetailEditComponent, { size: "lg" });

    ref.result.then(
      async (site: Site) => {
        await this.siteService.postSite(this.siteID, site);
        this.site = await this.siteService.getSite(this.siteID);
        await this.refreshMap();
      },
      reason => { });

    ref.componentInstance.site = this.site;
  }

  openProfileEdit() {
    const ref = this.modalService.open(SiteProfileEditComponent, { size: "lg" });

    ref.result.then(
      async ({ action, data }: {
        action: "save";
        data: {
          productsProduced: string;
          materialType: string;
          siteCategory: string;
          equipmentSoftware: string;
          comment: string;
          risk: string;
          location: string;
        }
      }) => {
        if (action === "save") {
          await this.siteService.postSite(this.siteID, data);
          this.site = await this.siteService.getSite(this.siteID);
        }
      },
      reason => { });

    ref.componentInstance.site = this.site;
  }

  openContactEdit(key: string) {
    if (this.site.lockedAt) {
      return false;
    }

    const allKeys = ["employeesCoordinator", "employeesFHCSContactPerson", "employeesFHCSKeySupplierManager", "employeesGMAdmin", "employeesOperation", "employeesQualityAssurance", "employeesResearchDevelopment", "employeesSiteQualityOfficer"];

    let allowedKeys: Array<string> = null;
    let contact: ContactPerson;

    const json = <string>this.site[key];

    if (json && json.length > 2) {
      contact = <ContactPerson>JSON.parse(json);
    } else {
      contact = new ContactPerson();
      allowedKeys = [];

      for (const k of allKeys) {
        const c = this.site[k];

        if (!c || c.length <= 2) {
          allowedKeys.push(k);
        }
      }
    }

    const ref = this.modalService.open(ContactEditComponent);
    const instance = <ContactEditComponent>ref.componentInstance;

    ref.result.then(
      async (result: string | { key: string; name: string; email: string; phone: string; fax: string; numberOfStaff: number; }) => {

        if (typeof result === "string") {
          await this.siteService.deleteContact(this.siteID, result);
        } else {
          await this.siteService.postContact(this.siteID, result);
        }

        this.site = await this.siteService.getSite(this.siteID);
      },
      reason => { });

    instance.contact = contact;
    instance.key = key;
    instance.allowedKeys = allowedKeys;

    return false;
  }

  async proposeAudit(id: number, type: number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          await this.auditService.postNewAudit(id, result, type);
          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = type;
    ref.componentInstance.required = false;
    ref.componentInstance.newState = InspectionState.Proposed;
  }

  async changeAuditType(id: number, type: number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
         await this.auditService.postSwitchAuditType(id, type, result);
         this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = type;
    ref.componentInstance.required = false;
    ref.componentInstance.newState = InspectionState.SwitchAuditType;
  }

  async proposeAssessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          await this.assessmentService.postNewAssessment(id, result);
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = 2;
    ref.componentInstance.required = false;
    ref.componentInstance.newState = InspectionState.Proposed;
  }

  async downloadEthicalAssessmentTemplate(id: number) {
    const templateUri = await this.attachmentService.getEthicalAssessmentTemplateUri();
    await this.documentService.downloadBlobUrl(templateUri);
  }

  async planProposedAudit(id: number) {
    this.busy = true;
    const ref = this.modalService.open(InspectionPlannerComponent, { size: "lg" });
    const instance = <InspectionPlannerComponent>ref.componentInstance;

    ref.result.then(
      async ({ date, comment }: { date: Date, comment: string }) => {
        try {
          await this.auditService.postDateChange(id, date, "plan", comment);
          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    if (this.nextAudit.inspectionDate)
      instance.selectedDate = new ParsePipe().transform(this.nextAudit.inspectionDate, 'YYYY-MM-DD').toDate();
  }

  async planProposedAssessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(InspectionPlannerComponent, { size: "lg" });
    const instance = <InspectionPlannerComponent>ref.componentInstance;

    ref.result.then(
      async ({ date, comment }: { date: Date, comment: string }) => {
        try {
          await this.assessmentService.postDateChange(id, date, "plan", comment);
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    if (this.nextAssessment.inspectionDate)
      instance.selectedDate = new ParsePipe().transform(this.nextAssessment.inspectionDate, 'YYYY-MM-DD').toDate();
  }

  async rejectProposedAudit(id: number, auditType?:number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {


          if (auditType > 2) {
            await this.auditService.postAuditDigitalChangeState(id, "reject", result);
          } else {
            await this.auditService.postChangeState(id, "reject", result);
          }

          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = 1;
    ref.componentInstance.required = true;
    ref.componentInstance.newState = InspectionState.Rejected;
  }

  async rejectProposedAssessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          await this.assessmentService.postChangeState(id, "reject", result)
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = 2;
    ref.componentInstance.required = true;
    ref.componentInstance.newState = InspectionState.Rejected;
  }

  async changeDateAudit(id: number) {
    this.busy = true;
    const ref = this.modalService.open(InspectionPlannerComponent, { size: "lg" });
    const instance = <InspectionPlannerComponent>ref.componentInstance;

    ref.result.then(
      async ({ date, comment }: { date: Date, comment: string }) => {

        try {
          await this.auditService.postDateChange(id, date, null, comment);
          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }

        if (this.nextAudit.state >= InspectionState.PlannedSentOut) {
          this.busy = true;
          const ref2 = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

          ref2.result.then(
            async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
              try {
                await this.mailingService.postSendAsync(this.nextAudit.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);
              }
              finally {
                this.busy = false;
              }
            },
            reason => { this.busy = false; });

          ref2.componentInstance.template = "InformSupplierAuditDateChanged";
          ref2.componentInstance.inspection = this.nextAudit;
          ref2.componentInstance.site = this.site;
        } else {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    instance.selectedDate = (new ParsePipe()).transform(this.nextAudit.inspectionDate, 'YYYY-MM-DD').toDate();
  }

  async changeDateAssessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(InspectionPlannerComponent, { size: "lg" });
    const instance = <InspectionPlannerComponent>ref.componentInstance;

    ref.result.then(
      async ({ date, comment }: { date: Date, comment: string }) => {

        try {
          await this.assessmentService.postDateChange(id, date, null, comment);
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }

        if (this.nextAssessment.state >= InspectionState.PlannedSentOut) {
          this.busy = true;
          const ref2 = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

          ref2.result.then(
            async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
              try {
                await this.mailingService.postSendAsync(this.nextAssessment.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);
              }
              finally {
                this.busy = false;
              }
            },
            reason => { this.busy = false; });

          ref2.componentInstance.template = "InformSupplierAssessmentDateChanged";
          ref2.componentInstance.inspection = this.nextAssessment;
          ref2.componentInstance.site = this.site;
        } else {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    instance.selectedDate = (new ParsePipe()).transform(this.nextAssessment.inspectionDate, 'YYYY-MM-DD').toDate();
  }

  async changeAuditor(id: number) {
    this.busy = true;
    const ref = this.modalService.open(ReassignInspectorComponent);

    ref.result.then(
      async reason => {
        try {
          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.inspection = this.nextAudit;
    ref.componentInstance.userDisplayName = this.nextAudit.inspectorDisplayName;
  }

  async changeAssessor(id: number) {
    this.busy = true;
    const ref = this.modalService.open(ReassignInspectorComponent);

    ref.result.then(
      async reason => {
        try {
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.inspection = this.nextAssessment;
    ref.componentInstance.userDisplayName = this.nextAssessment.inspectorDisplayName;
  }

  async sendMailAudit(id: number, auditType?: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });
    ref.result.then(
      async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
        try {
          await this.mailingService.postSendAsync(this.nextAudit.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);

          if (auditType > 2) {
            await this.auditService.postAuditDigitalChangeState(id, "plannedSentOut");
          } else {
            await this.auditService.postChangeState(id, "plannedSentOut");
          }
          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        } finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.template = "InformSupplierAuditPlanned";
    ref.componentInstance.inspection = this.nextAudit;
    ref.componentInstance.site = this.site;
  }

  async sendMailAssessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

    ref.result.then(
      async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
        try {
          await this.mailingService.postSendAsync(this.nextAssessment.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);
          await this.assessmentService.postChangeState(id, "plannedSentOut");
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        } finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.template = "InformSupplierAssessmentPlanned";
    ref.componentInstance.inspection = this.nextAssessment;
    ref.componentInstance.site = this.site;
  }

  async confirmAudit(id: number, auditType?: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

    ref.result.then(
      async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
        try {
          await this.mailingService.postSendAsync(this.nextAudit.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);

          if (auditType > 2) {
            await this.auditService.postAuditDigitalChangeState(id, "confirm");
          } else {
            await this.auditService.postChangeState(id, "confirm");
          }

          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.template = "InformSupplierAuditConfirmed";
    ref.componentInstance.inspection = this.nextAudit;
    ref.componentInstance.site = this.site;
  }

  async confirmAssessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

    ref.result.then(
      async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
        try {
          await this.mailingService.postSendAsync(this.nextAssessment.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);
          await this.assessmentService.postChangeState(id, "confirm");
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.template = "InformSupplierAssessmentConfirmed";
    ref.componentInstance.inspection = this.nextAssessment;
    ref.componentInstance.site = this.site;
  }

  async startAudit(id: number,auditType?:number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          if (auditType > 2) {
            await this.auditService.postAuditDigitalChangeState(id, "inProgress");
          } else {
            await this.auditService.postChangeState(id, "inProgress");
          }

          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = 1;
    ref.componentInstance.required = false;
    ref.componentInstance.newState = InspectionState.InProgress;
  }

  async startAssessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          await this.assessmentService.postChangeState(id, "inProgress", result)
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = 2;
    ref.componentInstance.required = false;
    ref.componentInstance.newState = InspectionState.InProgress;
  }

  async awaitActionPlan1Assessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

    ref.result.then(
      async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
        try {
          await this.assessmentService.postChangeState(id, "awaitingActionPlan1")
          await this.mailingService.postSendAsync(this.nextAssessment.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.template = "InformSupplierAssessmentFinished";
    ref.componentInstance.inspection = this.nextAssessment;
    ref.componentInstance.site = this.site;
  }

  async awaitActionPlan2Audit(id: number,auditType?: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

    ref.result.then(
      async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
        try {
          if (auditType > 2) {
            await this.auditService.postAuditDigitalChangeState(id, "awaitingActionPlan2");
          } else {
            await this.auditService.postChangeState(id, "awaitingActionPlan2");
          }

          await this.mailingService.postSendAsync(this.nextAudit.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);
          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.template = "InformSupplierAuditFinished";
    ref.componentInstance.inspection = this.nextAudit;
    ref.componentInstance.site = this.site;
  }

  async awaitActionPlan2Assessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

    ref.result.then(
      async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
        try {
          await this.mailingService.postSendAsync(this.nextAssessment.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);
          await this.assessmentService.postChangeState(id, "awaitingActionPlan2")
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.template = "RemindSupplierAssessmentActionPlan";
    ref.componentInstance.inspection = this.nextAssessment;
    ref.componentInstance.site = this.site;
  }

  async awaitCloseOutReport1Audit(id: number,auditType?: number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          if (auditType > 2) {
            await this.auditService.postAuditDigitalChangeState(id, "awaitingCloseOutReport1", result);
          } else {
            await this.auditService.postChangeState(id, "awaitingCloseOutReport1", result);
          }
          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = 1;
    ref.componentInstance.required = false;
    ref.componentInstance.newState = InspectionState.AwaitingCloseOutReport1;
  }

  async awaitCloseOutReport2Assessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          await this.assessmentService.postChangeState(id, "awaitingCloseOutReport2", result)
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = 2;
    ref.componentInstance.required = false;
    ref.componentInstance.newState = InspectionState.AwaitingCloseOutReport2;
  }

  async completeAudit(id: number,auditType?: number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          console.log("completeAudit()-->siteID="+this.siteID+", auditType="+ auditType);
          if (auditType > 2) {
            await this.auditService.postAuditDigitalChangeState(id, "completed", result);
            await this.auditService.postNewAudit(this.siteID, "automatic creation", 1); /* automatic creation of onsite Audit if criteria match in backend */
          } else {
            await this.auditService.postChangeState(id, "completed", result);
          }

          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
          this.latestAudit = await this.auditService.getLatest(this.siteID, true);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = 1;
    ref.componentInstance.required = false;
    ref.componentInstance.newState = InspectionState.Completed;
  }

  async completeAssessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          await this.assessmentService.postChangeState(id, "completed", result)
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
          this.latestAssessment = await this.assessmentService.getLatest(this.siteID, true);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.type = 2;
    ref.componentInstance.required = false;
    ref.componentInstance.newState = InspectionState.Completed;
  }

  async informInternalAudit(id: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

    ref.result.then(
      async ({ recipients, cc, subject, body }: { recipients: Array<{ role: string; contact: ContactPerson; }>, cc: string, subject: string, body: string }) => {
        try {
          await this.mailingService.postSendMultipleAsync(this.nextAudit.id, recipients.map(x => x.contact), this.mailingService.parseCc(cc), subject, body);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.useRegion = true;
    ref.componentInstance.template = "InformFHCSAuditFinished";
    ref.componentInstance.inspection = this.nextAudit;
    ref.componentInstance.site = this.site;
  }

  async informInternalAssessment(id: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

    ref.result.then(
      async ({ recipients, cc, subject, body }: { recipients: Array<{ role: string; contact: ContactPerson; }>, cc: string, subject: string, body: string }) => {
        try {
          await this.mailingService.postSendMultipleAsync(this.nextAssessment.id, recipients.map(x => x.contact), this.mailingService.parseCc(cc), subject, body);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.useRegion = true;
    ref.componentInstance.template = "InformFHCSAssessmentFinished";
    ref.componentInstance.inspection = this.nextAssessment;
    ref.componentInstance.site = this.site;
  }

  async resetAuditProposed(id: number,auditType?: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

    ref.result.then(
      async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
        try {
          await this.mailingService.postSendAsync(this.nextAudit.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);
          if (auditType > 2) {
            await this.auditService.postAuditDigitalChangeState(id, "proposed");
          } else {
            await this.auditService.postChangeState(id, "proposed")
          }

          this.nextAudit = await this.auditService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.template = "ResetAuditProposed";
    ref.componentInstance.inspection = this.nextAudit;
    ref.componentInstance.site = this.site;
  }

  async resetAssessmentProposed(id: number) {
    this.busy = true;
    const ref = this.modalService.open(SendMailInspectionComponent, { size: "lg" });

    ref.result.then(
      async ({ recipient, cc, subject, body }: { recipient: { role: string; contact: ContactPerson; }, cc: string, subject: string, body: string }) => {
        try {
          await this.mailingService.postSendAsync(this.nextAssessment.id, recipient.contact, this.mailingService.parseCc(cc), subject, body);
          await this.assessmentService.postChangeState(id, "proposed")
          this.nextAssessment = await this.assessmentService.getLatest(this.siteID, false);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.template = "ResetAssessmentProposed";
    ref.componentInstance.inspection = this.nextAssessment;
    ref.componentInstance.site = this.site;
  }

  async confirmUnlock() {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          await this.siteService.unlockSite(this.siteID);
          this.site = await this.siteService.getSite(this.siteID);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.description = "You are about to change this site to 'online' again. This will leave the auditor/assessor unable to sync at a later time.";
    ref.componentInstance.noComment = true;
    ref.componentInstance.required = false;
  }

  async confirmObsolete(makeObsolete: boolean) {
    this.busy = true;
    const ref = this.modalService.open(CommentPromptComponent);

    ref.result.then(
      async result => {
        try {
          await this.siteService.changeObsolete(this.siteID, makeObsolete);
          this.site = await this.siteService.getSite(this.siteID);
        }
        finally {
          this.busy = false;
        }
      },
      reason => { this.busy = false; });

    ref.componentInstance.description = `You are about to change this site to '${(makeObsolete ? "obsolete" : "active")}'. This will ${(makeObsolete ? "hide it from" : "show it on")} the dashboard.`;
    ref.componentInstance.noComment = true;
    ref.componentInstance.required = false;
  }

}
