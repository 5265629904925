import { Injectable } from '@angular/core';
import { InspectionList } from "../models/inspection";
import { BaseService } from "./base.service";


@Injectable()
export class InspectionsService extends BaseService {

  public getInspectionList(start = 0, limit = 10, sort?: { prop: string, dir: string }, filters?: any, search?: string) {
    return this.getMany<InspectionList>(InspectionList, 'api/inspections', start, limit, sort, filters, search);
  }

  public async save(model: InspectionForSave) {
    const target = model.id || "create";
    return await this.http.post(`api/inspections/${target}`, model, { headers: await this.getHeaders() }).toPromise();
  }

  public async postComment(id: number, comment: string) {
    return await this.http.post(`api/inspections/comment/${id}`, { comment: comment }, { headers: await this.getHeaders() }).toPromise();
  }

  public async postEthicalAssessmentComment(id: number, comment: string) {
    return await this.http.post(`api/inspections/eacomment/${id}`, { comment: comment }, { headers: await this.getHeaders() }).toPromise();
  }
}

interface InspectionForSave {
  id: number;
  focus: string;
  critical: string;
  purpose: string;
  summary: string;
  observations: Array<{
    id: number;
    area: number;
    number: number;
    action: string;
    comment: string;
    score: number;
    challenge: {
      id: number;
    }
  }>
}
