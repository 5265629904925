import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Region } from '../../../models/region';
import { Site } from '../../../models/site';
import { CountryInfo } from '../../../models/country-info';

import { SitesService } from '../../../data/sites.service';

@Component({
  selector: 'app-new-site',
  templateUrl: './new-site.component.html',
  styleUrls: ['./new-site.component.scss']
})
export class NewSiteComponent implements OnInit {

  countryInfos: CountryInfo[];
  regions: Region[];

  constructor(private siteService: SitesService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { countryInfos: CountryInfo[], regions: Region[] }) => {
        console.log("Route data ", data)
        this.countryInfos = data.countryInfos;
        this.regions = data.regions;
      });
  }

  async save(site: Site) {
    const { id } = await this.siteService.postSite("create", site);
    this.router.navigate(['/site', id]);
  }

}
