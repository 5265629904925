import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Audit, Assessment } from "../models/inspection";

import { InspectionState } from '../shared/inspection-state.enum';

@Component({
  selector: 'app-comment-prompt',
  templateUrl: './comment-prompt.component.html',
  styleUrls: ['./comment-prompt.component.scss']
})
export class CommentPromptComponent implements OnInit {

  InspectionState = InspectionState;

  @Input() type: number;
  @Input() required: boolean;
  @Input() noComment: boolean;

  @Input() title: string;
  @Input() description: string;
  @Input() template: string;

  @Input() inspection: Audit | Assessment;
  @Input() newState: InspectionState;

  comment: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (!this.template)
      this.template = InspectionState[this.newState];
  }

  continue() {
    this.activeModal.close(this.comment);
  }
}
