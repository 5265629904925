import { Pipe, PipeTransform } from '@angular/core';

import { Categories } from '../categories.enum';

@Pipe({
  name: 'categories'
})
export class CategoriesPipe implements PipeTransform {

  transform(cats: Categories, args?: any): any {

    if (cats > Categories.None) {
      const strs = [] as string[];

      const values = Object.keys(Categories)
        .map(k => Categories[k])
        .filter(v => typeof v === "number") as number[];

      for (const value of values) {
        // tslint:disable-next-line:no-bitwise
        if ((cats & value) === value) {
          switch (value) {
            case Categories.Professional:
              strs.push("Professional");
              break;
            case Categories.LaundryCare:
              strs.push("Laundry Care");
              break;
            case Categories.AboveTheFloor:
              strs.push("Above the Floor");
              break;
            case Categories.FloorCleaning:
              strs.push("Floor Cleaning");
              break;
            case Categories.OtherProducts:
              strs.push("Other Products");
              break;
          }
        }
      }

      return strs.sort().join(", ");
    }

    return "None";
  }

}
