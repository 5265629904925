import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, Input, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

import { TableComponent } from '../table/table.component';
import { ApiResponse } from "../data/api-response";
import { InspectionState } from '../shared/inspection-state.enum';

import { SitesService } from "../data/sites.service";
import { UsersService } from "../data/users.service";
import { Region } from "../models/region";
import { User } from "../models/user";

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent implements OnInit, OnChanges {

  InspectionState = InspectionState;

  @Input() cache: string;
  @Input() filters: any;

  regions: Region[];
  users: User[];

  @ViewChild(TableComponent) table: TableComponent;

  fetchDataFn: (start: number, limit: number) => Promise<ApiResponse<any>>;

  constructor(private siteService: SitesService, private userService: UsersService, @Inject(SESSION_STORAGE) private storage: StorageService, private activatedRoute: ActivatedRoute, private http: HttpClient) {
  }

  async ngOnInit() {
    this.setFiltersDefault();

    const filters = this.storage.get(this.getCacheKey("filters"));

    if (filters) {
      if (typeof filters.region === "string")
        filters.region = 0;

      this.filters = filters;
    }

    this.activatedRoute.queryParamMap.subscribe(params => {
      const region = +params.get("r");

      if (region > 0) {
        console.log(`Got filter for region ${region} from query.`);
        this.filters.region = region;
      }
    });

    this.setSortDefault();

    this.fetchDataFn = async (start: number, limit: number) =>
      await this.siteService.getSites(start, limit, this.table.sorts[0], this.filters, this.table.search);

    this.regions = await this.http.get<Region[]>(`api/regions`).toPromise();
    this.users = (await this.userService.getUsers(0, 1000, { prop: "DisplayName", dir: "asc" }, { realer: true }, null)).data;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && !changes.filters.firstChange) {
      this.table.refresh();
    }
  }

  getCacheKey(key: string) {
    return `${window.location.pathname}|${this.cache}|${key}`;
  }

  onFilterChange() {
    this.storage.set(this.getCacheKey("filters"), this.filters);
    this.table.refresh();
  }

  setFiltersDefault() {
    this.filters.sites = "";
    this.filters.state = "0";
    this.filters.evalType = "none";
    this.filters.region = "0";
    this.filters.evaluator = "00000000-0000-0000-0000-000000000000";
    this.filters.classification = "any";
  }

  setSortDefault() {
    this.table.sorts = [{ prop: 'name', dir: 'asc' }];
  }

  clearFiltersAndSort() {
    this.setFiltersDefault();
    this.setSortDefault();
    this.table.search = null;
    this.onFilterChange();
  }
}
