import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Site } from '../../../models/site';
import { Region } from '../../../models/region';
import { CountryInfo } from '../../../models/country-info';
import { RegionSite } from '../../../models/region-site';

import { SitesService } from '../../../data/sites.service';
import { SignedUri } from '../../../signed-uri';
import { Categories } from '../../categories.enum';

@Component({
  selector: 'app-edit-site-details',
  templateUrl: './edit-site-details.component.html',
  styleUrls: ['./edit-site-details.component.scss']
})
export class EditSiteDetailsComponent implements OnInit {

  Categories = Categories;

  @Input() site: Site;

  @Input() regions: Region[];
  @Input() countryInfos: CountryInfo[];

  @Output() save: EventEmitter<Site> = new EventEmitter();

  model = new Model();
  mode: "create" | "edit";

  constructor(private sitesService: SitesService) { }

  ngOnInit() {
    if (this.site) {
      this.mode = "edit";
      this.model.name = this.site.name;
      this.model.address = this.site.address;
      this.model.references = this.site.references;
      this.model.categories = this.site.categories;
      this.model.logoUri = this.site.logoUri;
      this.model.sapNumbers = [...this.site.sapNumbers];
      this.model.country = this.site.countryInfo.code3;

      for (const rs of this.site.regions) {
        this.model.regions[rs.region.id] = true;
      }

    } else {
      this.mode = "create";
      this.site = new Site();
    }
  }

  addSapNumber() {
    const val = prompt("Enter SAP number");

    if (val)
      this.model.sapNumbers.push(val);
  }

  removeSapNumber(sapNumber: string) {
    this.model.sapNumbers.splice(this.model.sapNumbers.indexOf(sapNumber), 1);
  }

  async onUploadFile(files?: FileList) {
    if (files && files.length === 1) {
      const uri = (await this.sitesService.upload(this.site.id, files[0])).logoUri;
      this.model.logoUri = uri;
    }
  }

  onSubmit() {
    this.site.name = this.model.name;
    this.site.address = this.model.address;
    this.site.references = this.model.references;
    this.site.categories = this.model.categories;
    this.site.logoUri = this.model.logoUri;
    this.site.sapNumbers = [...this.model.sapNumbers];
    this.site.countryInfo = this.countryInfos.filter(x => x.code3 === this.model.country)[0];

    this.site.regions = [];

    for (const key in this.model.regions) {
      if (this.model.regions.hasOwnProperty(key)) {
        const element = this.model.regions[key];

        if (element) {
          const rs = new RegionSite();
          rs.siteID = this.site.id;
          rs.regionID = +key;
          rs.region = this.regions.filter(x => x.id === rs.regionID)[0];
          this.site.regions.push(rs);
        }
      }
    }

    this.save.emit(this.site);
  }

  // tslint:disable:no-bitwise
  get categoriesProfessional() { return (this.model.categories & Categories.Professional) === Categories.Professional; }
  get categoriesLaundryCare() { return (this.model.categories & Categories.LaundryCare) === Categories.LaundryCare; }
  get categoriesAboveTheFloor() { return (this.model.categories & Categories.AboveTheFloor) === Categories.AboveTheFloor; }
  get categoriesFloorCleaning() { return (this.model.categories & Categories.FloorCleaning) === Categories.FloorCleaning; }
  get categoriesOtherProducts() { return (this.model.categories & Categories.OtherProducts) === Categories.OtherProducts; }

  set categoriesProfessional(value: boolean) { this.model.categories = (value) ? this.model.categories | Categories.Professional : this.model.categories & ~Categories.Professional; }
  set categoriesLaundryCare(value: boolean) { this.model.categories = (value) ? this.model.categories | Categories.LaundryCare : this.model.categories & ~Categories.LaundryCare; }
  set categoriesAboveTheFloor(value: boolean) { this.model.categories = (value) ? this.model.categories | Categories.AboveTheFloor : this.model.categories & ~Categories.AboveTheFloor; }
  set categoriesFloorCleaning(value: boolean) { this.model.categories = (value) ? this.model.categories | Categories.FloorCleaning : this.model.categories & ~Categories.FloorCleaning; }
  set categoriesOtherProducts(value: boolean) { this.model.categories = (value) ? this.model.categories | Categories.OtherProducts : this.model.categories & ~Categories.OtherProducts; }
  // tslint:enable:no-bitwise

}

class Model {
  name = "";
  address = "";
  country = "";
  references = "";
  categories = Categories.None;
  logoUri: SignedUri = null;
  regions: { [id: string]: boolean; } = {};
  sapNumbers: string[] = [];
}
