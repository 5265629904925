import { Pipe, PipeTransform } from '@angular/core';

import { Classification } from '../classification.enum';

@Pipe({
  name: 'classification'
})
export class ClassificationPipe implements PipeTransform {

  transform(value: number, args?: any): any {

    switch (+value) {
      case Classification.None: return "None";
      case Classification.Fhcs: return "FHCS";
      case Classification.Basic: return "Basic";
      case Classification.Key: return "Key";
      case Classification.Strategic: return "Strategic";
    }
  }

}
