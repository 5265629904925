import { Pipe, PipeTransform } from '@angular/core';

import { InspectionType } from '../inspection-type.enum';

@Pipe({
  name: 'inspectionType'
})
export class InspectionTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    switch (+value) {
      case InspectionType.Audit: return "Audit";
      case InspectionType.Assessment: return "Assessment";
    }
  }

}
