import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Site } from "../models/site";

@Component({
  selector: 'app-site-profile-edit',
  templateUrl: './site-profile-edit.component.html',
  styleUrls: ['./site-profile-edit.component.scss']
})
export class SiteProfileEditComponent implements OnInit {

  @Input() site: Site;

  model = {
    productsProduced: "",
    materialType: "",
    siteCategory: "",
    equipmentSoftware: "",
    comment: "",
    risk: "",
    location: "",
  };

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.model.productsProduced = this.site.productsProduced;
    this.model.materialType = this.site.materialType;
    this.model.siteCategory = this.site.siteCategory;
    this.model.equipmentSoftware = this.site.equipmentSoftware;
    this.model.comment = this.site.comment;
    this.model.risk = this.site.risk;
    this.model.location = this.site.location;
  }

  save() {
    this.activeModal.close({ action: "save", data: this.model });
  }
}
