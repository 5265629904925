import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { SettingsRoutingModule } from './settings-routing.module';

import { RegionResolver } from './services/region-resolver.service';
import { RegionUsersResolver } from './services/region-users-resolver.service';
import { RegionsResolver } from './services/regions-resolver.service';
import { StrategicKeySuppliersListResolver } from './services/strategic-key-suppliers-list-resolver.service';
import { MeResolver } from './services/me-resolver.service';

import { IndexComponent } from './components/index/index.component';
import { RegionEditComponent } from './components/region-edit/region-edit.component';
import { StrategicKeySuppliersListImportComponent } from './components/strategic-key-suppliers-list-import/strategic-key-suppliers-list-import.component';
import { StrategicKeySuppliersListReviewComponent } from './components/strategic-key-suppliers-list-review/strategic-key-suppliers-list-review.component';
import { NewSiteComponent } from './components/new-site/new-site.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgbModule,
    SettingsRoutingModule
  ],
  declarations: [IndexComponent, RegionEditComponent, StrategicKeySuppliersListImportComponent, StrategicKeySuppliersListReviewComponent, NewSiteComponent],
  providers: [RegionResolver, RegionUsersResolver, RegionsResolver, StrategicKeySuppliersListResolver, MeResolver]
})
export class SettingsModule { }
