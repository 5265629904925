import { ContentChild, Directive, HostBinding } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '.form-control:not([ngbNoFormControlValidation]),[ngbFormControlValidation]'
})
export class NgbFormControlValidationDirective {

  @ContentChild(NgControl)
  private ngControl: NgControl;

  @HostBinding('class.is-valid')
  get isValid() {
    return this.ngControl && this.ngControl.touched && this.ngControl.valid;
  }

  @HostBinding('class.is-invalid')
  get isInvalid() {
    return this.ngControl && this.ngControl.touched && this.ngControl.invalid;
  }

}
