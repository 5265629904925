import { Component, OnInit, Input, Output, HostListener, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  dragOver = false;

  @Input() acceptFileTypes = ["application/pdf", "image/jpeg", "image/png", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  @Input() maxSize = 15 * 1024 * 1024;
  @Input() disabled = false;
  @Input() buttonText = "Upload";
  @Input() buttonTextHover = "Drop Here";
  @Input() buttonClass = "btn-primary";

  @Output() uploadFile = new EventEmitter<FileList>();

  constructor() { }

  ngOnInit() { }

  saveFiles(files: FileList) {

    console.log("saveFiles")
    if (!this.isValidFile(files)) {
      this.uploadFile.emit(null);
      return;
    }

    this.uploadFile.emit(files);
  }

  private isValidFile(files: FileList) {

    if (files.length !== 1)
      return false;

    const file = files[0];

    if (this.acceptFileTypes.indexOf(file.type) < 0)
      return false;

    if (file.size > this.maxSize)
      return false;

    return true;
  }

  private isValidFileTransfer(files: DataTransferItemList) {

    if (files.length !== 1)
      return false;

    const file = files[0];

    if (this.acceptFileTypes.indexOf(file.type) < 0)
      return false;

    return true;
  }

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    this.dragOver = true;

    if (!this.isValidFileTransfer(event.dataTransfer.items))
      this.dragOver = false;

    return false;
  }

  @HostListener('dragend', ['$event']) onDragEnd(event) {
    this.dragOver = false;
    return false;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event) {
    this.dragOver = false;
    return false;
  }

  @HostListener('drop', ['$event']) onDrop(event) {
    this.dragOver = false;
    event.stopPropagation();
    this.saveFiles(event.dataTransfer.files);
    return false;
  }

  onFileChange(event) {
    this.saveFiles(event.target.files);
  }

  get buttonCls() {
    const d = { 'btn-success': this.dragOver };
    d[this.buttonClass] = !this.dragOver;

    return d;
  }

}
