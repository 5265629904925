import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { RegionUserAssigned } from '../models/region-user-assigned';

@Injectable()
export class RegionUsersResolver {
  constructor(private http: HttpClient, private router: Router) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<RegionUserAssigned[]> {
    const regionID = +route.paramMap.get("regionID");
    console.log("Resolving users of region " + regionID);

    const users = await this.http.get<RegionUserAssigned[]>(`api/regions/${regionID}/users/all`).toPromise();

    if (!users) {
      this.router.navigate(["/settings"]);
      return null;
    }

    return users;
  }

}
