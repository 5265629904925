import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CountryInfo } from '../../models/country-info';

@Injectable({
  providedIn: 'root'
})
export class CountryInfosResolver {
  constructor(private http: HttpClient, private router: Router) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CountryInfo[]> {
    console.log("Resolving countryInfos");

    const countryInfos = await this.http.get<CountryInfo[]>(`api/countryInfos`).toPromise();

    if (!countryInfos) {
      this.router.navigate(["/home"]);
      return null;
    }

    return countryInfos;
  }
}
