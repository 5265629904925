import { Injectable } from '@angular/core';
import { DateFormatPipe } from 'ngx-moment';

import { BaseService } from "./base.service";

import { Audit } from "../models/inspection";
import { EthicalAssessmentResult } from '../shared/ethical-assessment-result.enum';

@Injectable()
export class AuditService extends BaseService {

  public async getLatest(siteID: number, completed: boolean) {
    return await this.getSingle(Audit, `api/audits/latest/${siteID}?completed=${completed}`);
  }

  public getAudit(id: number) {
    return this.getSingle<Audit>(Audit, `api/audits/${id}`);
  }
  public getAuditDigital(id: number) {
    return this.getSingle<Audit>(Audit, `api/auditsdigital/${id}`);
  }

  public async postNewAudit(siteID: number, comment?: string, auditType?: number) {
    return this.http.post(`api/audits/${siteID}`, { comment: comment, audittype:auditType }, { headers: await this.getHeaders() }).toPromise();
  }

  public async postChangeState(id: number, state: "proposed" | "plan" | "reject" | "plannedSentOut" | "confirm" | "inProgress" | "awaitingActionPlan2" | "awaitingCloseOutReport1" | "awaitingCloseOutReport2" | "completed", comment?: string) {
    return this.http.post(`api/audits/${id}/${state}`, { comment: comment }, { headers: await this.getHeaders() }).toPromise();
  }

  public async postSwitchAuditType(id: number, auditType?: number, comment?: string) {
    return this.http.post(`api/audits/${id}/switch`, { type:auditType, comment: comment }, { headers: await this.getHeaders() }).toPromise();
  }

  public async postAuditDigitalChangeState(id: number, state: "proposed" | "plan" | "reject" | "plannedSentOut" | "confirm" | "inProgress" | "awaitingActionPlan2" | "awaitingCloseOutReport1" | "awaitingCloseOutReport2" | "completed", comment?: string) {
    return this.http.post(`api/auditsdigital/${id}/${state}`, { comment: comment }, { headers: await this.getHeaders() }).toPromise();
  }

  public async postChangeAuditor(id: number, userID: number) {
    return this.http.post(`api/audits/${id}/inspector`, { userID: userID }, { headers: await this.getHeaders() }).toPromise();
  }

  public async postDateChange(id: number, date: Date, state: "plan" | null, comment?: string) {
    return this.http.post(`api/audits/${id}/date`, { date: (new DateFormatPipe()).transform(date, "YYYY-MM-DD[T00:00:00Z]"), state: state, comment: comment }, { headers: await this.getHeaders() }).toPromise();
  }

  public async setEthicalAssessmentResult(id: number, ethicalAssessment: EthicalAssessmentResult) {
    return await this.http.post(`api/audits/${id}/ethicalAssessment`, ethicalAssessment, { headers: await this.getHeaders() }).toPromise();
  }
}
