import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ContactRolePipe } from './pipes/contact-role.pipe';
import { InspectionStatePipe } from './pipes/inspection-state.pipe';
import { InspectionTypePipe } from './pipes/inspection-type.pipe';
import { SiteNumberPipe } from './pipes/site-number.pipe';
import { QualityAreaPipe } from './pipes/quality-area.pipe';
import { ClassificationPipe } from './pipes/classification.pipe';
import { CategoriesPipe } from './pipes/categories.pipe';
import { EthicalAssessmentResultPipe } from './pipes/ethical-assessment-result.pipe';

import { NgbFormControlValidationDirective } from './directives/ngb-form-control-validation.directive';

import { DummyComponent } from "./components/dummy/dummy.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { RatingBadgeComponent } from "./components/rating-badge/rating-badge.component";
import { EditSiteDetailsComponent } from './components/edit-site-details/edit-site-details.component';
import { DebounceButtonComponent } from './components/debounce-button/debounce-button.component';
import { EthicalAssessmentBadgeComponent } from './components/ethical-assessment-badge/ethical-assessment-badge.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
  ],
  declarations: [
    ContactRolePipe,
    InspectionStatePipe,
    InspectionTypePipe,
    SiteNumberPipe,
    QualityAreaPipe,
    ClassificationPipe,
    CategoriesPipe,
    EthicalAssessmentResultPipe,

    NgbFormControlValidationDirective,

    DummyComponent,
    FileUploadComponent,
    RatingBadgeComponent,
    EthicalAssessmentBadgeComponent,
    EditSiteDetailsComponent,
    DebounceButtonComponent,
  ],
  exports: [
    ContactRolePipe,
    InspectionStatePipe,
    InspectionTypePipe,
    SiteNumberPipe,
    QualityAreaPipe,
    ClassificationPipe,
    CategoriesPipe,
    EthicalAssessmentResultPipe,

    NgbFormControlValidationDirective,

    DummyComponent,
    FileUploadComponent,
    RatingBadgeComponent,
    EthicalAssessmentBadgeComponent,
    EditSiteDetailsComponent,
    DebounceButtonComponent,
  ]
})
export class SharedModule { }
