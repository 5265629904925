import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { addDays, startOfDay, isEqual } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ParsePipe } from 'ngx-moment';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewDay,
} from 'angular-calendar';
import {
  WeekDay,
} from 'calendar-utils';

import { ApiResponse } from "../data/api-response";
import { CalendarService } from "../data/calendar.service";
import { Calendar } from "../models/calendar";

import { SiteNumberPipe } from '../shared/pipes/site-number.pipe';
import { InspectionType } from '../shared/inspection-type.enum';

type CalendarPeriod = 'week' | 'month';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  @Input() enableSelection = false;
  @Input() enableModals = true;
  @Input() selectedDate: Date = null;

  dateSelected: Date;
  @Output() onDateSelected = new EventEmitter<Date>();

  view: CalendarPeriod = 'month';

  today = startOfDay(new Date());

  minDate: Date = this.today;
  maxDate: Date = addDays(this.today, 4 * 365);

  viewDate: Date = this.today;

  modalActive: NgbModalRef;
  modalData = {
    date: this.today,
    events: new Array<CalendarEvent>(),
    close: (result?: any) => this.modalActive.close(result),
    dismiss: (reason?: any) => this.modalActive.dismiss(reason),
  };

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [
  ];

  constructor(
    private calendarService: CalendarService,
    private modal: NgbModal,
    private router: Router,
  ) {
    this.dateOrViewChanged();
  }

  async ngOnInit() {
    if (this.selectedDate)
      this.viewDate = this.selectedDate;

    const all = await this.calendarService.getAll();

    for (const item of <Calendar[]>all.data) {
      this.events.push({
        start: (new ParsePipe()).transform(item.inspectionInspectionDate, null).toDate(),
        allDay: true,
        title: `${(new SiteNumberPipe().transform(item.siteSiteNumber))} ${item.siteName} (${item.inspectorDisplayName})`,
        color: colors.blue,
        meta: item
      });
    }

    this.refresh.next();
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarPeriod): void {
    this.view = view;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  dateIsValid(date: Date): boolean {
    return date >= this.minDate && date <= this.maxDate;
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    if (this.enableSelection) {
      body.forEach(day => {
        day.cssClass = '';

        if (!this.dateIsValid(day.date)) {
          day.cssClass += ' cal-disabled';
        }

        if (isEqual(day.date, this.selectedDate)) {
          day.cssClass += ' cal-day-selected';
        }
      });
    }
  }

  beforeWeekViewRender({ header }: { header: WeekDay[] }): void {
    if (this.enableSelection) {
      header.forEach(day => {
        day.cssClass = '';

        if (!this.dateIsValid(day.date)) {
          day.cssClass += ' cal-disabled';
        }

        if (isEqual(day.date, this.selectedDate)) {
          day.cssClass += ' cal-day-selected';
        }
      });
    }
  }

  dayClicked({ date, events }: {
    date: Date;
    events: CalendarEvent[];
    inMonth: boolean;
    isFuture: boolean;
    isPast: boolean;
    isToday: boolean;
    isWeekend: boolean;
  }) {
    this.dateSelected = date;
    this.onDateSelected.emit(this.dateSelected);

    this.modalData.date = date;
    this.modalData.events = events;
    this.refresh.next();

    if (this.enableModals)
      this.modalActive = this.modal.open(this.modalContent, { size: 'lg' });
  }

  dayHeaderClicked({ date }: {
    date: Date;
    isFuture: boolean;
    isPast: boolean;
    isToday: boolean;
    isWeekend: boolean;
  }) {
    this.dateSelected = date;
    this.onDateSelected.emit(this.dateSelected);

    this.refresh.next();
  }

  eventClicked(event: CalendarEvent) {
    this.modalData.date = event.start;
    this.modalData.events = [event];

    if (this.enableModals)
      this.modalActive = this.modal.open(this.modalContent, { size: 'lg' });
  }

  goToInspection(meta) {
    this.modalActive.close();

    if (meta.inspectionType === InspectionType.Audit)
      this.router.navigate(['/audit', meta.inspectionID]);
    else if (meta.inspectionType === InspectionType.Assessment)
      this.router.navigate(['/assessment', meta.inspectionID]);
  }
}
