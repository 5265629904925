import { Component, OnInit, OnChanges, Input, HostBinding, SimpleChanges } from '@angular/core';

import { Site } from "../models/site";

@Component({
  // tslint:disable-next-line:component-selector
  selector: '*[app-contact-list-item]',
  templateUrl: './contact-list-item.component.html',
  styleUrls: ['./contact-list-item.component.scss']
})
export class ContactListItemComponent implements OnInit, OnChanges {

  @HostBinding() class;
  @HostBinding() hidden = false;

  @Input() disabled: boolean;
  @Input() key: string;
  @Input() site: Site;

  contact: any;

  ngOnInit() {
    if (!this.disabled)
      this.class = "list-group-item list-group-item-action flex-column align-items-start";
    else
      this.class = "list-group-item flex-column align-items-start";

    this.parseJson();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.site) {
      this.parseJson();
    }
  }

  parseJson() {
    const json: string = this.site[this.key];

    if (json && json.length > 2) {
      this.contact = JSON.parse(json);
      this.hidden = false;
    } else {
      this.hidden = true;
    }
  }

}
