import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { Classification } from '../../../shared/classification.enum';

import { StrategicKeySuppliersListResult } from '../../models/strategic-key-suppliers-list-result';

@Component({
  selector: 'app-strategic-key-suppliers-list-review',
  templateUrl: './strategic-key-suppliers-list-review.component.html',
  styleUrls: ['./strategic-key-suppliers-list-review.component.scss']
})
export class StrategicKeySuppliersListReviewComponent implements OnInit {

  Classification = Classification;

  result: StrategicKeySuppliersListResult;
  disable: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { result: StrategicKeySuppliersListResult }) => {
        console.log("Route data ", data);
        this.result = data.result;
        this.disable = false;
      });
  }

  async save() {
    const toast = this.toastr.info("Saving changes...", "Please wait", {
      disableTimeOut: true
    });

    await this.http.post<StrategicKeySuppliersListResult>(`api/import/strategic-key-suppliers-list/${this.result.id}`, { commit: true }).toPromise();

    toast.toastRef.close();
    this.toastr.success("Import completed!");
    this.disable = true;
  }

}
