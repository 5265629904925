import { Component, OnInit, Input } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtcPipe } from 'ngx-moment';

import { Inspection, Audit, Assessment } from "../models/inspection";
import { Site } from "../models/site";
import { RegionRecipient } from '../models/region-recipient';
import { MailingService } from "../data/mailing.service";
import { ContactPerson } from "../models/contact-person";
import { RegionSite } from '../models/region-site';
import { InspectionType } from '../shared/inspection-type.enum';
import Utils from '../shared/utils';

@Component({
  selector: 'app-send-mail-inspection',
  templateUrl: './send-mail-inspection.component.html',
  styleUrls: ['./send-mail-inspection.component.scss']
})
export class SendMailInspectionComponent implements OnInit {

  //#region InformSupplierAuditConfirmed
  static readonly InformSupplierAuditConfirmed = `Dear *|SiteContactPerson|*,

The current Quality Audit is confirmed and will be conducted on *|InspectionDate|* for 3 days.

If you have any questions or need any additional information, please don’t hesitate to contact me directly.

Best Regards,
*|InspectorDisplayName|*`;

  //#endregion

  //#region InformSupplierAssessmentConfirmed
  static readonly InformSupplierAssessmentConfirmed = `Dear *|SiteContactPerson|*,

The current Quality Assessment is confirmed and will be conducted on *|InspectionDate|* for 2 days.

If you have any questions or need any additional information, please don’t hesitate to contact me directly.

Best Regards,
*|InspectorDisplayName|*`;
  //#endregion

  //#region InformSupplierAuditPlanned
  static readonly InformSupplierAuditPlanned = `Dear *|SiteContactPerson|*,

Per our company policy, we perform a Quality System Audit of our suppliers, warehouses, and production facilities to ensure that these achieve/maintain a minimum acceptable standard of quality for our products.

Attached are our 16 Quality Areas Requirements that will be used as a guide during the Audit. The audit will consist of some conference room discussions and a plant tour. It is important that the right people responsible for the areas listed in the 16 QA Requirements be available to answer questions as they arise.

PDF_GUIDELINES_LINK
  
It is expected that your site receives a minimum Audit rating of 65 % QA Capability.

Additionally, please find the Site Information Page also attached.  Please review the details and provide us with any updates to the key information.  This site information will be the opportunity for you to position the features, advantages, and benefits of *|SiteName|* with all of our FHCS sites worldwide.

PDF_SITEINFO_LINK

The plan will be to run the audit in 2,5 days beginning on *|InspectionDate|* at 9:00 am and finish at approximately 5:00 pm.  Additionally, we will need a 0,5 day for report writing and a presentation of the audit results. Please see the attached estimated agenda.

PDF_AUDITAGENDA_LINK

If you have any questions don’t hesitate to contact me directly.

Best Regards,
*|InspectorDisplayName|*`;

  //#endregion

  //#region InformSupplierAssessmentPlanned
  static readonly InformSupplierAssessmentPlanned = `Dear *|SiteContactPerson|*,

Per our company policy, we perform a Quality System Assessment of our raw material, starting material, and intermediate material suppliers to ensure that these achieve/maintain a minimum acceptable standard of quality for our products.

Attached are our 16 Quality Areas Requirements that will be used as a guide during the Assessment. The assessment will consist of some conference room discussions and a plant tour. It is important that the right people responsible for the areas listed in the 16 QA Requirements be available to answer questions as they arise.

PDF_GUIDELINES_LINK

It is expected that your site receives an assessment rating of OK.

Additionally, please find the Site Information Page also attached.  Please review the details and provide us with any updates to the key information.  This site information will be the opportunity for you to position the features, advantages, and benefits of *|SiteName|* with all of our FHCS sites worldwide.

PDF_SITEINFO_LINK

Send the completed form back to me as soon as you can.

The plan will be to run the assessment in 2 days beginning on *|InspectionDate|* at 9:00 am and finish at approximately 5:00 pm.  These two days will include time for report writing and a presentation of the assessment results.

PDF_ASSESSMENTAGENDA_LINK

If you have any questions or need any additional information, please don’t hesitate to contact me directly.

Best Regards,
*|InspectorDisplayName|*`;
  //#endregion

  //#region InformSupplierAuditDateChanged
  static readonly InformSupplierAuditDateChanged = `Dear *|SiteContactPerson|*,

The current Quality System Audit date was changed and will now be conducted on *|InspectionDate|*.

If you have any questions don’t hesitate to contact me directly.

Best Regards,
*|InspectorDisplayName|*`;

  //#endregion

  //#region InformSupplierAssessmentDateChanged
  static readonly InformSupplierAssessmentDateChanged = `Dear *|SiteContactPerson|*,

The current Quality System Assessment date was changed and will now be conducted on *|InspectionDate|*.

If you have any questions don’t hesitate to contact me directly.

Best Regards,
*|InspectorDisplayName|*`;
  //#endregion

  //#region InformSupplierAuditFinished
  static readonly InformSupplierAuditFinished = `Dear *|SiteContactPerson|*,

  Thank you for the time commitment and dedication that you made to the Quality System and me over the last few days for our Quality Systems Audit. I truly appreciated your openness and hospitality while visiting your facility.

*|SiteName|* has achieved a QAC rating of *|Rating|*.

Please use this link to access your Audit report: 
AUDIT_REPORT_LINK

Please use this link to access your Action Plan: 
ACTION_PLAN_LINK

Summary of findings 
*|SummaryOfFindings|*

An initial report of all actions taken and all corrective actions planned is expected to be submitted to *|FHCSKSM|* and me before *|30DaysResponseDue|* using the action plan document.

Additionally, your final response summarizing all actions should be sent to us before *|90DaysResponseDue|* using the updated action plan document.

If you have any questions or need any additional information, please contact me directly.

Best Regards,
*|InspectorDisplayName|*`;

  //#endregion

  //#region InformFHCSAuditFinished
  static readonly InformFHCSAuditFinished = `Dear Colleagues,

Please find attached the report for the Quality System audit performed at *|SiteName|* on *|InspectionDate|*.

AUDIT_REPORT_LINK

*|SiteName|* has achieved a QAC rating of *|Rating|*.
  
Summary of findings 
*|SummaryOfFindings|*
  
If you have any questions or need any additional information, please do not hesitate to contact me directly.

Best Regards,
*|InspectorDisplayName|*`;
  //#endregion

  //#region InformSupplierAssessmentFinished
  static readonly InformSupplierAssessmentFinished = `Dear *|SiteContactPerson|*,

Please find attached the report and the action plan for the Quality System assessment performed at *|SiteName|* on *|InspectionDate|*.

*|SiteName|* has achieved a QAC rating of *|Rating|*. 

Please use this link to access your Assessment report: 
ASSESSMENT_REPORT_LINK

Please use this link to access your Action Plan: 
ACTION_PLAN_LINK

Summary of findings 
*|SummaryOfFindings|*

An initial report of all actions taken and all corrective actions planned is expected to be submitted to *|FHCSKSM|* and me before *|30DaysResponseDue|* using the action plan document.

Additionally, your final response summarizing all actions should be sent to us before *|90DaysResponseDue|* using the updated action plan document.

If you have any questions or need any additional information, please contact me directly.

Best Regards,
*|InspectorDisplayName|*`;
  //#endregion

  //#region InformFHCSAssessmentFinished
  static readonly InformFHCSAssessmentFinished = `Dear Colleagues,

Please find the assessment report for the Global Quality Assessment at *|SiteName|* on *|InspectionDate|*.

ASSESSMENT_REPORT_LINK

*|SiteName|* has achieved a QAC rating of *|Rating|*.
  
Summary of findings 
*|SummaryOfFindings|*
  
If you have any questions or need any additional information, please do not hesitate to contact me directly.

Best Regards,
*|InspectorDisplayName|*`;
  //#endregion

  //#region RemindSupplierAssessmentActionPlan
  static readonly RemindSupplierAssessmentActionPlan = `Dear *|SiteContactPerson|*,

An FHCS Quality Assessment took place on your site at *|InspectionDate|* and the findings of the assessment have been shared with you.

An initial report of all actions taken and all corrective actions planned is expected to be submitted to *|FHCSKSM|* and me before *|30DaysResponseDue|* using the action plan document.

Additionally, your final response summarizing all actions should be sent to us before *|90DaysResponseDue|* using the updated action plan document.
  
Please provide the required information with FHCS Quality Team before the end of next week.
  
If you have any questions or need any additional information, please contact me directly.
  
Best Regards,
*|InspectorDisplayName|*`;
  //#endregion

  //#region ResetAuditProposed
  static readonly ResetAuditProposed = `Dear *|SiteContactPerson|*,

The current Quality System Audit scheduled to be conducted on *|InspectionDate|* was cancelled.
We will restart the process and contact you again in the next few days.

If you have any questions don’t hesitate to contact me directly.

Best Regards,
*|InspectorDisplayName|*`;
  //#endregion

  //#region ResetAssessmentProposed
  static readonly ResetAssessmentProposed = `Dear *|SiteContactPerson|*,

The current Quality System Assessment scheduled to be conducted on *|InspectionDate|* was cancelled.
We will restart the process and contact you again in the next few days.

If you have any questions don’t hesitate to contact me directly.

Best Regards,
*|InspectorDisplayName|*`;
  //#endregion

  mailForm: FormGroup;

  @Input() template: "InformSupplierAuditPlanned" | "InformSupplierAssessmentPlanned" | "InformSupplierAuditConfirmed" | "InformSupplierAssessmentConfirmed" | "InformSupplierAuditDateChanged" | "InformSupplierAssessmentDateChanged" | "InformSupplierAuditFinished" | "InformSupplierAssessmentFinished" | "InformFHCSAuditFinished" | "InformFHCSAssessmentFinished" | "RemindSupplierAssessmentActionPlan" | "ResetAuditProposed" | "ResetAssessmentProposed";
  @Input() inspection: Inspection;
  @Input() site: Site;
  @Input() useRegion: boolean = false;

  model = {
    recipient: <{ role: "employeesCoordinator" | "employeesGMAdmin" | "employeesQualityAssurance" | "employeesSiteQualityOfficer" | "employeesOperation" | "employeesResearchDevelopment" | "employeesFHCSContactPerson" | "employeesFHCSKeySupplierManager" | "lastPrimaryContact"; contact: ContactPerson; }>null,
    recipients: new Array<{ role: "employeesCoordinator" | "employeesGMAdmin" | "employeesQualityAssurance" | "employeesSiteQualityOfficer" | "employeesOperation" | "employeesResearchDevelopment" | "employeesFHCSContactPerson" | "employeesFHCSKeySupplierManager" | "lastPrimaryContact"; contact: ContactPerson; }>(),
    cc: "",
    subject: "",
    body: "",
    addressBook: new Array<{ role: "employeesCoordinator" | "employeesGMAdmin" | "employeesQualityAssurance" | "employeesSiteQualityOfficer" | "employeesOperation" | "employeesResearchDevelopment" | "employeesFHCSContactPerson" | "employeesFHCSKeySupplierManager" | "lastPrimaryContact"; contact: ContactPerson; }>()
  };

  constructor(public activeModal: NgbActiveModal, public mailingService: MailingService, private http: HttpClient, private fb: FormBuilder) {
  }

  createForm() {
    if (!this.useRegion) {
      this.mailForm = this.fb.group({
        recipient: ["", Validators.required],
        cc: [""],
        subject: ["", Validators.required],
        body: ["", Validators.required],
      });
    }
    else {
      this.mailForm = this.fb.group({
        cc: [""],
        subject: ["", Validators.required],
        body: ["", Validators.required],
      });
    }
  }

  async ngOnInit() {
    this.createForm();

    if (!this.useRegion) {
      this.updateAddressBook();
    }
    else {
      await this.updateRecipientsWithRegionAsync();
    }

    this.updateModel();
  }

  onSubmit() {
    this.activeModal.close(this.model);
  }

  formStyle(name: string) {
    if (this.mailForm.get(name).pristine)
      return {};

    return {
      'is-valid': this.mailForm.get(name).valid,
      'is-invalid': this.mailForm.get(name).invalid
    };
  }

  countCc(val: string) {
    return this.mailingService.parseCc(val).length;
  }

  updateModel() {
    console.log(`Using template ${this.template}`);

    switch (this.template) {
      case "InformSupplierAuditPlanned":
        this.model.subject = "Upcoming: FHCS Quality System Audit";
        break;
      case "InformSupplierAssessmentPlanned":
        this.model.subject = "Upcoming: FHCS Quality System Assessment";
        break;
      case "InformSupplierAuditConfirmed":
        this.model.subject = "Confirmed: FHCS Quality System Audit";
        break;
      case "InformSupplierAssessmentConfirmed":
        this.model.subject = "Confirmed: FHCS Quality System Assessment";
        break;
      case "InformSupplierAuditDateChanged":
        this.model.subject = "Date Changed: FHCS Quality System Audit";
        break;
      case "InformSupplierAssessmentDateChanged":
        this.model.subject = "Date Changed: FHCS Quality System Assessment";
        break;
      case "InformSupplierAuditFinished":
        this.model.subject = "Finished: FHCS Quality System Audit";
        break;
      case "InformSupplierAssessmentFinished":
        this.model.subject = "Finished: FHCS Quality System Assessment";
        break;
      case "InformFHCSAuditFinished":
        this.model.subject = "Finished: FHCS Quality System Audit";
        break;
      case "InformFHCSAssessmentFinished":
        this.model.subject = "Finished: FHCS Quality System Assessment";
        break;
      case "RemindSupplierAssessmentActionPlan":
        this.model.subject = "Reminder: FHCS Quality System Assessment Action Plan";
        break;
      case "ResetAuditProposed":
        this.model.subject = "Cancelled: FHCS Quality System Audit";
        break;
      case "ResetAssessmentProposed":
        this.model.subject = "Cancelled: FHCS Quality System Assessment";
        break;
    }

    this.model.body = SendMailInspectionComponent[this.template];

    const utcPipe = new UtcPipe();
    const percentPipe = new PercentPipe("en-US");

    const utcInspectionDate = utcPipe.transform(this.inspection.inspectionDate);

    const nowPlus30 = Utils.skipWeekend(utcPipe.transform(new Date()).add(30, "days")).format("YYYY-MM-DD");
    const nowPlus90 = Utils.skipWeekend(utcPipe.transform(new Date()).add(90, "days")).format("YYYY-MM-DD");
    const date = utcInspectionDate.format("YYYY-MM-DD");
    const datePlus = Utils.skipWeekend(utcInspectionDate.add(3, "days")).format("YYYY-MM-DD");

    const replace = (token: string, replacement: string) => {
      const rgx = new RegExp("\\*\\|" + token + "\\|\\*", "g");
      const body = this.model.body.replace(rgx, replacement);

      if (this.model.body !== body) {
        this.model.body = body;
        console.log(`Replacing *|${token}|* with "${replacement}"`);
      }

      this.model.body = body;
    }

    if (this.model.recipient)
      replace("SiteContactPerson", this.model.recipient.contact.Name || this.model.recipient.contact.EMail);

    replace("SiteName", this.site.name);
    replace("InspectionDate", date);
    replace("InspectionDatePlus3Days", datePlus);
    replace("InspectorDisplayName", this.inspection.inspectorComputedName || 'Unassigned');
    replace("SummaryOfFindings", this.inspection.summary || 'None');
    replace("30DaysResponseDue", nowPlus30);
    replace("90DaysResponseDue", nowPlus90);

    if (this.inspection.type === InspectionType.Audit) {
      const audit = <Audit>this.inspection;
      replace("Rating", percentPipe.transform(audit.result, "1.0-0"));
    } else if (this.inspection.type === InspectionType.Assessment) {
      const assessment = <Assessment>this.inspection;
      replace("Rating", assessment.result === 1 ? "OK" : "NOK");
    }

    const ksm = this.parseAddressBookEntry(this.site.employeesFHCSKeySupplierManager, "employeesFHCSKeySupplierManager");

    if (ksm) {
      replace("FHCSKSM", ksm.contact.Name);
    }
    else {
      replace("FHCSKSM", "the key supplier manager");
    }
  }

  updateAddressBook() {
    if (this.site.employeesCoordinator) this.addAddressBookEntry(this.site.employeesCoordinator, "employeesCoordinator");
    if (this.site.employeesGMAdmin) this.addAddressBookEntry(this.site.employeesGMAdmin, "employeesGMAdmin");
    if (this.site.employeesQualityAssurance) this.addAddressBookEntry(this.site.employeesQualityAssurance, "employeesQualityAssurance");
    if (this.site.employeesSiteQualityOfficer) this.addAddressBookEntry(this.site.employeesSiteQualityOfficer, "employeesSiteQualityOfficer");
    if (this.site.employeesOperation) this.addAddressBookEntry(this.site.employeesOperation, "employeesOperation");
    if (this.site.employeesResearchDevelopment) this.addAddressBookEntry(this.site.employeesResearchDevelopment, "employeesResearchDevelopment");
    if (this.site.employeesFHCSContactPerson) this.addAddressBookEntry(this.site.employeesFHCSContactPerson, "employeesFHCSContactPerson");
    if (this.site.employeesFHCSKeySupplierManager) this.addAddressBookEntry(this.site.employeesFHCSKeySupplierManager, "employeesFHCSKeySupplierManager");

    if (this.inspection.primaryContact) {
      const contact = <ContactPerson>JSON.parse(this.inspection.primaryContact);
      const fromAddressBook = this.model.addressBook.filter(x => x.contact.EMail === contact.EMail)[0];

      if (fromAddressBook) {
        this.model.recipient = fromAddressBook;
      } else {
        this.model.recipient = this.addAddressBookEntry(contact, "lastPrimaryContact");
      }
    }

    if (!this.model.recipient) {
      this.model.recipient = { role: null, contact: { Name: "[no valid recipient found in contact list for this site]" } };
    }
  }

  async updateRecipientsWithRegionAsync() {
    const regionTasks = this.site.regions.map(x => this.http.get<Array<RegionRecipient>>(`api/regions/${x.regionID}/recipients`).toPromise());

    for (const regionTask of regionTasks) {
      const rcpts = await regionTask;

      for (const rcpt of rcpts) {
        this.model.recipients.push({ role: null, contact: { Name: rcpt.name, EMail: rcpt.emailAddress } });
      }
    }
  }

  parseAddressBookEntry(person: string | ContactPerson, role: "employeesCoordinator" | "employeesGMAdmin" | "employeesQualityAssurance" | "employeesSiteQualityOfficer" | "employeesOperation" | "employeesResearchDevelopment" | "employeesFHCSContactPerson" | "employeesFHCSKeySupplierManager" | "lastPrimaryContact") {
    if (!person) return null;

    let contact: ContactPerson;

    if (typeof person === 'string') {
      if (person.length < 3) return null;
      contact = <ContactPerson>JSON.parse(person);
    } else {
      contact = person;
    }

    return { role: role, contact: contact };
  }

  addAddressBookEntry(person: string | ContactPerson, role: "employeesCoordinator" | "employeesGMAdmin" | "employeesQualityAssurance" | "employeesSiteQualityOfficer" | "employeesOperation" | "employeesResearchDevelopment" | "employeesFHCSContactPerson" | "employeesFHCSKeySupplierManager" | "lastPrimaryContact") {
    const obj = this.parseAddressBookEntry(person, role);

    if (!obj) return null;

    if (this.mailingService.isValidEmailAddress(obj.contact.EMail)) {
      this.model.addressBook.push(obj);

      if (!this.model.recipient) {
        this.model.recipient = obj;
      }

      return obj;
    }

    return null;
  }

  concatRegionNames(regions: RegionSite[]) {
    return regions.map(x => x.region.name).join("; ");
  }
}
