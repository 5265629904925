import { Component, OnInit, Input } from '@angular/core';

import { EthicalAssessmentResult } from '../../ethical-assessment-result.enum';

@Component({
  selector: 'app-ethical-assessment-badge',
  templateUrl: './ethical-assessment-badge.component.html',
  styleUrls: ['./ethical-assessment-badge.component.scss']
})
export class EthicalAssessmentBadgeComponent implements OnInit {
  EthicalAssessmentResult = EthicalAssessmentResult;

  @Input() result: EthicalAssessmentResult;

  constructor() { }

  ngOnInit() {
  }

}
