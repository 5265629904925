import { EthicalAssessmentResult } from '../shared/ethical-assessment-result.enum';
import { InspectionState } from '../shared/inspection-state.enum';
import { InspectionType } from '../shared/inspection-type.enum';
import { SignedUri } from '../signed-uri';
import { Observation } from './observation';
import { Site } from './site';
import { User } from './user';

export class Inspection {
    id: number;
    type: InspectionType;
    inspectionDate: string;
    reportDate: string;
    closeOutReportDate: string | null;

    purpose: string;
    summary: string;
    focus: string;
    critical: string;

    siteID: number;
    site: Site;

    inspectorID?: number;
    inspectorDisplayName?: string;
    inspectorComputedName?: string;

    changedBy?: User;
    sysStartTime?: string;
    sysEndTime?: string;
    observations?: Array<Observation>;

    state: InspectionState;
    stateComment: string;

    lastAttachmentUri: SignedUri;
    lastAttachmentState: number;

    primaryContact: string;

    ethicalAssessment: EthicalAssessmentResult;
    ethicalAssessmentUri: SignedUri;
    ethicalAssessmentComment: string | null;

    _history?: any;
}

export class Audit extends Inspection {
    focus: string;
    qualityAreas: string;

    get result() {
        if (!this.observations)
            return 0;

        let sum = 0;
        let count = 0;

        for (const ob of this.observations.filter(x => (x.score || 0) >= 0 && x.number === 0)) {
            sum += ob.score || 0;
            count++;
        }

        if (!count)
            return null;

        return sum / (8.0 * count);
    }
}

export class Assessment extends Inspection {

    get result() {
        if (!this.observations)
            return 0;

        const prioItemsNotOk = this.observations.filter(x => x.challenge.priority && x.number > 0 && x.score === 0);

        return (prioItemsNotOk.length === 0) ? 1.0 : 0.0;
    }
}

export class InspectionList {
    id: number;
    type: number;
    inspectionDate: string;
    reportDate: string;

    siteID: number;
    siteName: string;

    inspectorID: number | null;
    inspectorDisplayName: string | null;

    state: InspectionState;

    result: number | null;
}
