import { Pipe, PipeTransform } from '@angular/core';

import { QualityArea } from '../quality-area.enum';

@Pipe({
  name: 'qualityArea'
})
export class QualityAreaPipe implements PipeTransform {

  transform(value: number, args?: any): any {

    switch (+value) {
      case QualityArea.Leadership: return "Leadership & Internal Audits";
      case QualityArea.Training: return "Training";
      case QualityArea.DesignConstruction: return "Design & Construction";
      case QualityArea.SpecificationsStandards: return "Specifications & Standards";
      case QualityArea.WrittenProcedures: return "Written Procedures";
      case QualityArea.Validation: return "Validation & Change Control";
      case QualityArea.HousekeepingPestControl: return "Housekeeping & Pest Control";
      case QualityArea.StartingMaterials: return "Starting Materials";
      case QualityArea.Making: return "Making";
      case QualityArea.Packing: return "Packing";
      case QualityArea.Records: return "Records";
      case QualityArea.QualityControl: return "Quality Control: Devices, Solutions & People";
      case QualityArea.FinishedProductRelease: return "Finished Product Release";
      case QualityArea.StorageHandlingFinishedGoods: return "Storage & Handling of Finished Products";
      case QualityArea.Complaints: return "Complaints & Quality Incidents";
      case QualityArea.AccountabilityContractors: return "Accountability for Finished Goods Suppliers";
    }
  }

}
