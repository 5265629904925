import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { Region } from '../../../models/region';
import { RegionUserAssigned } from '../../models/region-user-assigned';
import { Me } from '../../../models/me';

@Component({
  selector: 'app-region-edit',
  templateUrl: './region-edit.component.html',
  styleUrls: ['./region-edit.component.scss']
})
export class RegionEditComponent implements OnInit {

  region: Region;
  users: RegionUserAssigned[];
  me: Me;

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { region: Region, users: RegionUserAssigned[], me: Me }) => {
        console.log("Route data ", data);
        this.region = data.region;
        this.users = data.users;
        this.me = data.me;
      });
  }

  async addUser(user: RegionUserAssigned) {
    console.log("Adding user " + user.displayName);

    try {
      await this.http.put(`api/regions/${this.region.id}/users/${user.id}`, {}).toPromise();
      this.toastr.success("Added!");
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.toastr.error(`Error: ${e.status} ${e.statusText}`);
        console.error(`${e.status} ${e.statusText}`);
      }
    }

    this.refresh();
  }

  async removeUser(user: RegionUserAssigned) {
    console.log("Removing user " + user.displayName);

    try {
      await this.http.delete(`api/regions/${this.region.id}/users/${user.id}`, {}).toPromise();
      this.toastr.success("Removed!");
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.toastr.error(`Error: ${e.status} ${e.statusText}`);
        console.error(`${e.status} ${e.statusText}`);
      }
    }

    this.refresh();
  }

  private refresh() {
    this.router.navigate(["settings", "regions", this.region.id]);
  }

}
