import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";

@Injectable()
export class ChartsService extends BaseService {

  public async getSpeedo(mode: number) {
    return await this.get<any>(`api/charts/speedo/${mode}`);
  }

}
