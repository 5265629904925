import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.scss']
})
export class DummyComponent implements OnInit {

  @Input() count = 8;
  @Input() skew = 15;
  @Input() char = "█";

  blocks: string;

  constructor() { }

  ngOnInit() {
    let count = this.count;

    if (this.skew > 0) {
      const rand = 10 * Math.random();
      const sign = (rand < 5) ? +1 : -1;
      const percent = this.skew / 100.0;
      count = this.count * (1 + percent * rand);
    }

    this.blocks = this.char.repeat(count);
  }

}
