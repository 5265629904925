import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";

import { User } from "../models/user";

@Injectable()
export class UsersService extends BaseService {

  private allUsers: User[];

  public async getUser(id: number) {
    if (!this.allUsers) {
      this.allUsers = (await this.getUsers()).data;
    }

    return this.allUsers.find(x => x.id === id);
  }

  public getUsers(start = 0, limit = 10, sort?: { prop: string, dir: string }, filters?: any, search?: string) {
    return this.getMany(User, 'api/users', start, limit, sort, filters, search);
  }

  public getAuditors(start = 0, limit = 10, sort?: { prop: string, dir: string }, filters?: any, search?: string) {
    return this.getMany(User, 'api/users/auditors', start, limit, sort, filters, search);
  }

  public getAssessor(start = 0, limit = 10, sort?: { prop: string, dir: string }, filters?: any, search?: string) {
    return this.getMany(User, 'api/users/assessors', start, limit, sort, filters, search);
  }
}
