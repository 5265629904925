

// export const environment = {
//   name: <"production" | "staging" | "development">"development",

//   adalConfig: <adal.Config>{
//     tenant: 'fhp-ww.com',
//     clientId: '49eb3923-0c28-41b4-9c87-ae8cddb53d32',
//     cacheLocation: "localStorage",
//     redirectUri: "http://localhost:4200/",
//     postLogoutRedirectUri: "http://localhost:4200/bye",
//     endpoints: {
//       'http://localhost:5000/': '49eb3923-0c28-41b4-9c87-ae8cddb53d32',
//     }
//   }

// };  


export const environment = {
  name: <"production" | "staging" | "development">"production",

  adalConfig: <adal.Config>{
    tenant: 'fhp-ww.com',
    clientId: '49eb3923-0c28-41b4-9c87-ae8cddb53d32',
    cacheLocation: "localStorage",
    redirectUri: "https://aadb.fhp-ww.com/",
    postLogoutRedirectUri: "https://aadb.fhp-ww.com/bye",
    endpoints: {
      'https://fhcs-aadb-backend.azurewebsites.net/': '49eb3923-0c28-41b4-9c87-ae8cddb53d32',
    }
  }

};


// export const environment = {
//   name: <"production" | "staging" | "development">"staging",

//   adalConfig: <adal.Config>{
//     tenant: 'fhp-ww.com',
//     clientId: '49eb3923-0c28-41b4-9c87-ae8cddb53d32',
//     cacheLocation: "localStorage",
//     redirectUri: "https://fhcs-aadb-staging.azurewebsites.net/",
//     postLogoutRedirectUri: "https://fhcs-aadb-staging.azurewebsites.net/bye",
//     endpoints: {
//       'https://fhcs-aadb-backend-staging.azurewebsites.net/': '49eb3923-0c28-41b4-9c87-ae8cddb53d32',
//     }
//   }

// };



// export const environment = {
//   name: <"production" | "staging" | "development">"production",

//   adalConfig: <adal.Config>{
//     tenant: 'fhp-ww.com',
//     clientId: '49eb3923-0c28-41b4-9c87-ae8cddb53d32',
//     cacheLocation: "localStorage",
//     redirectUri: "https://aadb.fhp-ww.com/",
//     postLogoutRedirectUri: "https://aadb.fhp-ww.com/bye",
//     endpoints: {
//       'https://fhcs-aadb-backend.azurewebsites.net/': '49eb3923-0c28-41b4-9c87-ae8cddb53d32',
//     }
//   }

// };