import { Component, HostBinding, HostListener } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'button[debounce]',
  template: '<ng-content></ng-content>'
})
export class DebounceButtonComponent {

  @HostBinding() disabled: boolean;

  @HostListener('click', ['$event.target']) onClick() {
    this.disabled = true;
    setTimeout(() => this.disabled = false, 2000);
  }

}
