import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";

import { Site } from "../models/site";
import { ContactPerson } from "../models/contact-person";
import { SignedUri } from "../signed-uri";

@Injectable()
export class SitesService extends BaseService {

  public getSite(id: number) {
    return this.getSingle(Site, `api/sites/${id}`);
  }

  public getSites(start = 0, limit = 10, sort?: { prop: string, dir: string }, filters?: any, search?: string) {
    return this.getMany(Site, 'api/sites', start, limit, sort, filters, search);
  }

  public async postSite(id: number | "create", data: Site | { productsProduced: string; materialType: string; siteCategory: string; equipmentSoftware: string; comment: string; risk: string; location: string; }) {
    return this.http.post<{ id: number, siteNumber: number }>(`api/sites/${id}`, data, { headers: await this.getHeaders() }).toPromise();
  }

  public async lockSite(id: number) {
    return this.http.post(`api/sites/lock/${id}`, null, { headers: await this.getHeaders() }).toPromise();
  }

  public async unlockSite(id: number) {
    return this.http.post(`api/sites/unlock/${id}`, null, { headers: await this.getHeaders() }).toPromise();
  }

  public async changeObsolete(id: number, makeObsolete: boolean) {
    return this.http.post(`api/sites/obsolete/${id}`, makeObsolete, { headers: await this.getHeaders() }).toPromise();
  }

  public async upload(id: number, file: File) {
    const url = `api/sites/logo/${id}`;

    return await this.http
      .post<{ logoUri: SignedUri }>(url, file, { headers: await this.getHeaders() })
      .toPromise();
  }

  public async getHistory(id: number) {
    return await this.http
      .get<Array<any>>(`api/sites/history/${id}`, { headers: await this.getHeaders() })
      .toPromise();
  }

  public async postContact(siteId: number, result: { key: string; name: string; email: string; phone: string; fax: string; numberOfStaff: number; }) {
    return await this.http
      .post(`api/sites/contacts/${siteId}`, result, { headers: await this.getHeaders() })
      .toPromise();
  }

  public async deleteContact(siteId: number, key: string) {
    return await this.http
      .delete(`api/sites/contacts/${siteId}/${key}`, { headers: await this.getHeaders() })
      .toPromise();
  }
}
