
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BaseService } from '../../data/base.service';

@Injectable()
export class HttpRequestInterceptorService {

  private static root = "https://localhost:5001/";
  //private static root = "https://fhcs-aadb-backend-staging.azurewebsites.net/";
  public static get Root() { return HttpRequestInterceptorService.root; }

  constructor() {
    console.log(`Environment: ${environment.name}`);

    if (environment.name === "production")
      HttpRequestInterceptorService.root = "https://fhcs-aadb-backend.azurewebsites.net/";
    else if (environment.name === "staging")
      HttpRequestInterceptorService.root = "https://fhcs-aadb-backend-staging.azurewebsites.net/";
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const props = {
      headers: req.headers,
      url: req.url,
    };

    if (props.url.indexOf("https://") === -1 && props.url.indexOf("http://") === -1) {
      props.url = HttpRequestInterceptorService.Root + props.url;
    }

    if (!props.headers.has("Content-Type")) {
      props.headers = props.headers.set("Content-Type", "application/json")
      props.headers = props.headers.set("Authorization", "Bearer "+localStorage.getItem('adal.idtoken'))
    }
    return next.handle(req.clone(props));
  }

}
