export class Me {
    id: number;
    displayName: string;
    email: string;
    externalID: string;

    firstName: string;
    lastName: string;

    permissions: {
        admin: boolean,
        user: boolean,
        auditor: boolean,
        assessor: boolean,
        allocator: boolean,
    };
}
