import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Region } from '../../models/region';

@Injectable()
export class RegionResolver implements Resolve<Region> {
  constructor(private http: HttpClient, private router: Router) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const regionID = +route.paramMap.get("regionID");
    console.log("Resolving region " + regionID);

    const region = await this.http.get<Region>(`api/regions/${regionID}`).toPromise();

    if (!region) {
      this.router.navigate(["/settings"]);
      return null;
    }

    return region;
  }

}
