import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ContactPerson } from "../models/contact-person";

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit {

  @Input() key: string;
  @Input() contact: ContactPerson;
  @Input() allowedKeys: string[];

  model = {
    key: <string>null,
    name: "",
    email: "",
    phone: "",
    fax: "",
    numberOfStaff: 0,
  };

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.model.key = this.key;

    if (this.contact) {
      this.model.name = this.contact.Name;
      this.model.email = this.contact.EMail;
      this.model.phone = this.contact.Telefon;
      this.model.fax = this.contact.Fax;
      this.model.numberOfStaff = this.contact.NumberOfStaff;
    }
  }

  delete() {
    this.activeModal.close(this.model.key);
  }

  save() {
    this.activeModal.close(this.model);
  }

}
