import { Injectable } from '@angular/core';
import { BaseService } from "./base.service";
import { HttpRequestInterceptorService } from '../core/services/http-request-interceptor.service';
import { SignedUri } from '../signed-uri';

@Injectable()
export class DocumentService extends BaseService {

  async GetDoucmentSignUrl(url: string) {
    let urlwithouthttp = url.split('://')[1];
    const response = await this.http.get<{ documentUri: SignedUri }>(`api/attachment/docs/DoucmentSignUrl?url=${urlwithouthttp}`, { headers: await this.getHeaders() }).toPromise();
    return response.documentUri;
  }

  getReportUrl(inspectionID: number) {
    return `${HttpRequestInterceptorService.Root}api/inspections/${inspectionID}/report`;
  }

  getActionPlanUrl(inspectionID: number) {
    return `${HttpRequestInterceptorService.Root}api/inspections/${inspectionID}/actionplan`;
  }

  getSiteInfoUrl(siteID: number) {
    return `${HttpRequestInterceptorService.Root}api/sites/${siteID}/info`;
  }

  getSiteStatusListUrl() {
    return `${HttpRequestInterceptorService.Root}api/export/site/status`;
  }

  async getHeadersForViewer() {
    const authorization = await this.AuthorizationHeader$.toPromise();

    return {
      Authorization: authorization,
    };
  }

  async downloadBlobUrl(src: string | SignedUri) {
    const download = await this.downloadFile(src);

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(download.response.body, download.fileName);
    } else {
      const url = window.URL.createObjectURL(download.response.body);

      const el = document.createElement("a");
      el.setAttribute("style", "display:none");
      el.href = url;
      el.download = download.fileName;

      document.body.appendChild(el);

      el.click();

      setTimeout(() => {
        document.body.removeChild(el);
        window.URL.revokeObjectURL(url);
      }, 10000);
    }
  }

  //TODO change different File Handling with Download from Blob
  //not Downlaod from frontend or Backend and so on. 

  private async downloadFile(url: string | SignedUri) {
    if (typeof url === "object") {
      url = url.urlSigned;
    } else {
      url = await this.GetDoucmentSignUrl(url.toString());
    }

    let headers = await this.getSignHeaders();

    const response = await this.http
      .get(url.toString(), { responseType: "blob", observe: "response", headers: headers })
      .toPromise();

    const a = document.createElement("a");
    a.href = url.toString();
    let fileName = decodeURI(a.pathname.split("/").pop());

    return { response: response, fileName: fileName };
  }

  private async downloadFileFromBackend(url: string) {
    let headers = await this.getHeaders();

    const response = await this.http.get(url, { responseType: "blob", observe: "response", headers: headers })
      .toPromise();

    const a = document.createElement("a");
    a.href = url.toString();
    let fileName = decodeURI(a.pathname.split("/").pop());

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(response.body, fileName);
    } else {
      const url = window.URL.createObjectURL(response.body);

      const el = document.createElement("a");
      el.setAttribute("style", "display:none");
      el.href = url;
      el.download = fileName;

      document.body.appendChild(el);

      el.click();

      setTimeout(() => {
        document.body.removeChild(el);
        window.URL.revokeObjectURL(url);
      }, 10000);
    }
  }

}
