import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from "@angular/router";
import { Observable, Subject, timer } from "rxjs";
import { distinctUntilChanged, debounce } from "rxjs/operators";
import { AdalService } from "adal-angular4";
import { LoadingBarService } from '@ngx-loading-bar/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  navigating = new Subject<boolean>();

  user: Observable<adal.User>;
  loaded = false;

  isMainUserMenuCollapsed = true;
  isMainMenuCollapsed = true;

  constructor(
    private adalService: AdalService,
    private loadingBar: LoadingBarService,
    router: Router
  ) {
    this.navigating
      .pipe(distinctUntilChanged(), debounce(start => timer(start ? 200 : 0)))
      .subscribe(start => start ? this.loadingBar.start() : this.loadingBar.complete());

    router.events.subscribe(event => {
      if (event instanceof NavigationStart)
        this.navigating.next(true);
      else if (event instanceof NavigationEnd)
        this.navigating.next(false);
      else if (event instanceof NavigationCancel)
        this.navigating.next(false);
      else if (event instanceof NavigationError)
        this.navigating.next(false);
    });

    this.adalService.init(environment.adalConfig);

    window["logOut"] = () => this.logOut();
    window["getUserInfo"] = () => this.adalService.userInfo;
  }

  async ngOnInit() {

    console.log("Calling handleWindowCallback");
    this.adalService.handleWindowCallback();

    if (window !== window.parent) {
      console.log("App is running in an iframe. Terminating.");
      return;
    }

    if (window.location.pathname === "/bye") {
      console.log("Bye!");
      return;
    }

    console.log("Running ngOnInit");

    if (!this.adalService.userInfo.authenticated) {
      console.log("Login: start");
      this.adalService.login();
      console.log("Login: end");
    }

    if (this.adalService.userInfo.authenticated) {
      this.user = <any>this.adalService.getUser();
      this.loaded = true;
    }
  }

  public logOut() {
    this.adalService.logOut();
  }
}
