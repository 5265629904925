export class Challenge {
    id: number;

    area: number;
    number: number;

    text: string;

    archived: boolean;
    priority: boolean;
}
