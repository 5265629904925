import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { DocumentService } from '../../../data/document.service';

import { Region } from '../../../models/region';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  regions: Region[];

  constructor(public documentService: DocumentService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { regions: Region[] }) => {
        console.log("Route data ", data)
        this.regions = data.regions;
      });
  }

}
