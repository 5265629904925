import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Audit, Assessment } from "../models/inspection";
import { User } from "../models/user";
import { ApiResponse } from "../data/api-response";
import { UsersService } from "../data/users.service";
import { AuditService } from "../data/audit.service";
import { AssessmentService } from "../data/assessment.service";
import { InspectionType } from '../shared/inspection-type.enum';

@Component({
  selector: 'app-reassign-inspector',
  templateUrl: './reassign-inspector.component.html',
  styleUrls: ['./reassign-inspector.component.scss']
})
export class ReassignInspectorComponent implements OnInit {
  users: Promise<ApiResponse<Array<User>>>;
  user: User;

  @Input() inspection: Audit | Assessment;
  @Input() userDisplayName: string;

  constructor(
    public activeModal: NgbActiveModal,
    private usersService: UsersService,
    private auditService: AuditService,
    private assessmentService: AssessmentService,
  ) {
  }

  ngOnInit() {
    this.users = this.usersService.getUsers(0, 1000, { prop: "DisplayName", dir: "asc" }, { real: true }, null);
  }

  async onSelectNew(displayName: string) {
    this.user = (await this.users).data.filter(x => x.displayName === displayName)[0];
  }

  async save() {
    if (this.user && (this.inspection.type === InspectionType.Audit ||this.inspection.type === InspectionType.DigitalAudit)) {
      await this.auditService.postChangeAuditor(this.inspection.id, this.user.id);
    } else if (this.user && this.inspection.type === InspectionType.Assessment) {
      await this.assessmentService.postChangeAssessor(this.inspection.id, this.user.id);
    }

    this.activeModal.close();
  }
}
