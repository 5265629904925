import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule, Routes } from '@angular/router';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule } from 'angular-calendar';
import { MomentModule } from 'ngx-moment';
import { NgDragDropModule } from 'ng-drag-drop';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ToastrModule } from 'ngx-toastr';

import 'hammerjs';

import { AdalService, AdalGuard, AdalInterceptor } from 'adal-angular4';

import { SharedModule } from './shared/shared.module';
import { SettingsModule } from './settings/settings.module';

import { HttpRequestInterceptorService } from './core/services/http-request-interceptor.service';

import { AppComponent } from './app.component';
import { ByeComponent } from './bye/bye.component';
import { TableComponent } from './table/table.component';
import { AuditEditComponent } from './audit-edit/audit-edit.component';
import { AuditsComponent } from './audits/audits.component';
import { AssessmentsComponent } from './assessments/assessments.component';
import { CalendarComponent } from './calendar/calendar.component';
import { InspectionComponent } from './inspection/inspection.component';
import { InspectionListComponent } from './inspection-list/inspection-list.component';
import { HomeComponent } from './home/home.component';
import { SiteComponent } from './site/site.component';
import { SiteHistoryComponent } from './site-history/site-history.component';
import { SiteListComponent } from './site-list/site-list.component';
import { SitesComponent } from './sites/sites.component';

import { AuditService } from './data/audit.service';
import { AssessmentService } from './data/assessment.service';
import { AttachmentService } from './data/attachment.service';
import { InspectionsService } from './data/inspections.service';
import { DocumentService } from './data/document.service';
import { SitesService } from './data/sites.service';
import { UsersService } from './data/users.service';
import { CalendarService } from './data/calendar.service';
import { ChallengesService } from './data/challenges.service';
import { ChartsService } from './data/charts.service';
import { MailingService } from './data/mailing.service';
import { InfoService } from './data/info.service';

import { ContactEditComponent } from './contact-edit/contact-edit.component';
import { SiteDetailEditComponent } from './site-detail-edit/site-detail-edit.component';
import { SiteProfileEditComponent } from './site-profile-edit/site-profile-edit.component';
import { ReassignInspectorComponent } from './reassign-inspector/reassign-inspector.component';
import { CommentPromptComponent } from './comment-prompt/comment-prompt.component';
import { SendMailInspectionComponent } from './send-mail-inspection/send-mail-inspection.component';
import { InspectionPlannerComponent } from './inspection-planner/inspection-planner.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { AssessmentEditComponent } from './assessment-edit/assessment-edit.component';
import { ContactListItemComponent } from './contact-list-item/contact-list-item.component';

const appRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: 'bye',
    component: ByeComponent,
    pathMatch: 'full'
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: 'audit/:id',
    component: InspectionComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: 'audits',
    component: AuditsComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: 'audit/edit/:auditid',
    component: AuditEditComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: 'assessment/:id',
    component: InspectionComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: 'assessments',
    component: AssessmentsComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: 'assessment/edit/:assessmentid',
    component: AssessmentEditComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: 'site/:siteid/audit/new',
    component: AuditEditComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: 'site/:id',
    component: SiteComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: 'site/:id/history',
    component: SiteHistoryComponent,
    canActivate: [AdalGuard],
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    ByeComponent,
    TableComponent,
    AuditsComponent,
    AuditEditComponent,
    HomeComponent,
    CalendarComponent,
    SiteComponent,
    SiteHistoryComponent,
    SiteListComponent,
    SitesComponent,
    InspectionComponent,
    InspectionListComponent,
    AssessmentsComponent,
    AssessmentEditComponent,
    DateTimePickerComponent,
    ContactListItemComponent,
    // entryComponents:
    ContactEditComponent,
    SiteDetailEditComponent,
    SiteProfileEditComponent,
    ReassignInspectorComponent,
    CommentPromptComponent,
    SendMailInspectionComponent,
    InspectionPlannerComponent,
  ],
  imports: [
    SharedModule,
    SettingsModule,
    NgbModule,
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),
    LoadingBarModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    CalendarModule.forRoot(),
    NgDragDropModule.forRoot(),
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    StorageServiceModule,
    PdfViewerModule,
    MomentModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCgbDNDh66X5uhKWVNFPUAZZkFn-DGXSBg",
      libraries: ["places"]
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdalInterceptor,
      multi: true
    },
    AdalService,
    AdalGuard,
    AuditService,
    AssessmentService,
    AttachmentService,
    InspectionsService,
    DocumentService,
    SitesService,
    UsersService,
    CalendarService,
    ChallengesService,
    ChartsService,
    MailingService,
    InfoService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ContactEditComponent,
    SiteDetailEditComponent,
    SiteProfileEditComponent,
    ReassignInspectorComponent,
    CommentPromptComponent,
    SendMailInspectionComponent,
    InspectionPlannerComponent,
  ]
})

export class AppModule { }
