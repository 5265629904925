export enum InspectionState {
    Unknown = 0,
    Cancelled = 1,

    Proposed = 10,
    Rejected = 11,

    Planned = 20,
    PlannedSentOut = 21,
    Confirmed = 22,

    InProgress = 30,

    AwaitingActionPlan1 = 40, // old: CompletedWithIssues = 40
    AwaitingActionPlan2 = 41,

    AwaitingCloseOutReport1 = 50,
    AwaitingCloseOutReport2 = 51,

    Completed = 60, // old: 41
    SwitchAuditType = 61, //This is just a flag for prompting a confirmation when switching Audit Type
}
